import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ImageType } from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../../components/button/Button";
import { ImageUploadForm } from "../../components/imageUpload/ImageUploadForm";
import InputField from "../../components/Input/InputField";
import { ICategoriesForm } from "../../interface/categories/CategoriesInterface";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../redux/ducks/siteLoader.duck";
import {
  AddCategories,
  UpdateCategory,
} from "../../services/CategoriesServices";
import { CategoryValidationSchema } from "../../validation/category/CategoryValidation";
import Card from "../../wrapper/Card";

const AddCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const siteLoader = useSelector(siteLoaderSelector);

  useEffect(() => {
    if (location?.state !== null) {
      reset({
        name: location?.state?.name,
        image: [
          {
            dataURL: `${process.env.REACT_APP_API_URL}${location?.state?.image}`,
          },
        ],
      });
    }
  }, [location.state]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<ICategoriesForm>({
    resolver: yupResolver(CategoryValidationSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      image: null,
    },
  });

  const OnSubmit: SubmitHandler<ICategoriesForm> = async (
    data: ICategoriesForm
  ) => {
    dispatch(showLoader());
    if (!id) {
      const reqObj = new FormData();
      reqObj.append("name", data.name);
      let updateImages: [ImageType?, string?, null?] = [];
      if (data.image !== null) {
        data.image.forEach((file: any) => {
          if (file.file instanceof File) reqObj.append("image", file.file);
          else if (file.dataURL && process.env.REACT_APP_PUBLIC_API_URL)
            updateImages.push(
              file.dataURL.replace(process.env.REACT_APP_PUBLIC_API_URL, "")
            );
          else {
            reqObj.append(
              "image",
              updateImages.length >= 1 && updateImages
                ? JSON.stringify(updateImages)
                : JSON.stringify(null)
            );
          }
        });
      }
      const response = await AddCategories(reqObj);
      const { response_type, message } = response.data;
      if (response_type === "success") {
        toast.success(message);
        navigate("/categories");
      } else {
        toast.error(message);
      }
      dispatch(hideLoader());
    } else {
      const reqObj = new FormData();
      reqObj.append("name", data.name);
      let updateImages: [ImageType?, string?, null?] = [];
      if (data.image !== null) {
        data.image.forEach((file: any) => {
          if (file.file instanceof File) {
            reqObj.append("image", file.file);
            reqObj.append("imageUpdated", 'true');
          } else if (file.dataURL && process.env.REACT_APP_PUBLIC_SOCKET_URL)
            updateImages.push(
              file.dataURL.replace(process.env.REACT_APP_PUBLIC_API_URL, "")
            );
        });
      }
      const response = await UpdateCategory(id, reqObj);
      const { response_type, message } = response.data;
      if (response_type === "success") {
        toast.success(message);
        navigate("/categories");
      } else {
        toast.error(message);
      }
      dispatch(hideLoader());
    }
  };

  return (
    <Card
      title={id ? "Update Category" : "Add Category"}
      link="categories"
      backButton="categories"
    >
      <form className="auth_form" encType="multipart/form-data">
        <div>
          <div className="flex flex-wrap">
            <div className="xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
              <InputField
                name="name"
                control={control}
                type="text"
                placeholder="Enter Name"
                errors={errors}
                label="Name"
              />
            </div>
            <div className="w-1/2">
              <ImageUploadForm
                control={control}
                name="image"
                errors={errors}
                label={"Category Image"}
                multiple={false}
              />
            </div>
          </div>

          <hr className="my-5" />
          <div className="mt-5">
            <div className="flex justify-end mt-5">
              <Button
                buttonVariant={"primaryBlackBorder"}
                text={"Cancel"}
                type={"reset"}
                onlyDisable={!isDirty}
                onClickHandler={() => {
                  reset();
                  navigate(-1);
                }}
              />
              <Button
                buttonVariant={"primary"}
                text={"Submit"}
                type={"submit"}
                onlyDisable={!isDirty}
                onClickHandler={handleSubmit(OnSubmit)}
                loader={siteLoader}
                className="text-white flex items-center"
              />
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default AddCategory;
