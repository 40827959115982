import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BackButton from "../components/button/BackButton";
import Button from "../components/button/Button";
import { PlusIcon } from "../components/svgIcons/SvgIcons";

const Card = ({
  title,
  link,
  children,
  isButton,
  buttonText,
  backButton,
  withId,
}: {
  title: string | React.ReactNode;
  link: string;
  children: React.ReactChild;
  hideAddButton?: boolean;
  isButton?: boolean;
  buttonText?: string;
  backButton?: string;
  withId?: number | string;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <>
      {pathname.match(/add|edit|view|[0-9]/g) &&
        backButton &&
        backButton !== "" && <BackButton title={backButton} />}
      <div className="card bg-white shadow-shadow2 rounded-lg">
        <div className="card-header py-5 md:mx-7 mx-5 border-b border-224BB5/[0.1] flex flex-wrap items-center justify-between">
          <h4 className="text-lg font-medium">
            <span className="inline-block">
              {typeof title === "string" ? title : title}
            </span>
          </h4>
          {buttonText && (
            <>
              <Button
                buttonVariant={"primary"}
                text={buttonText}
                onClickHandler={() =>
                  navigate(`/${link}/add${withId ? "/" + withId : ""}`)
                }
                icon={<PlusIcon />}
                type={"submit"}
                className="text-white capitalize"
              />
            </>
          )}
        </div>

        <div className="card-body py-7 px-7">
          {children ? children : "No Data Found"}
        </div>
      </div>
    </>
  );
};

export default Card;
