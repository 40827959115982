import { AxiosDelete, AxiosGet, AxiosPost, AxiosPut } from "../axios/Config";

export const GetCategories = (query?: string) => {
  return query ? AxiosGet(`/category${query}`) : AxiosGet(`/category`);
};

export const AddCategories = (data: object) => {
  return AxiosPost("/category", data);
};

export const DeleteCategory = (id: string) => {
  return AxiosDelete(`/category/${id}`);
};

export const GetCategoryById = (id?: string) => {
  return AxiosGet(`/category?id=${id}`, {});
};

export const UpdateCategory = (id: string | number, data: object) => {
  return AxiosPut(`/category/${id}`, data);
};
