import { AxiosDelete, AxiosGet, AxiosPost, AxiosPut } from "../axios/Config";

export const GetProjects = (query?: string) => {
  return query ? AxiosGet(`/project${query}`) : AxiosGet(`/category`);
};

export const AddProject = (data: object) => {
  return AxiosPost("/project", data);
};

export const DeleteProject = (id: string) => {
  return AxiosDelete(`/project/${id}`);
};

export const UpdateProject = (id: string | number, data: object) => {
  return AxiosPut(`/project/${id}`, data);
};
