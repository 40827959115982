const DashboardLoader = () => (
  <div className="flex justify-center rounded-full items-center  w-full h-full  bg-inputBorder z-30">
    <div
      className="spinner-border animate-spin inline-block w-8 h-8 border-2 rounded-full border-l-white/0 border-t-3369FD border-r-3369FD  border-b-3369FD"
      role="status"
    ></div>
  </div>
);

export default DashboardLoader;
