import { useNavigate } from "react-router-dom";

import { BackArrowIcon } from "../svgIcons/SvgIcons";

const BackButton = ({
  title,
  tabValue,
  pathName,
}: {
  title: string;
  tabValue?: string;
  pathName?: string;
}) => {
  const navigate = useNavigate();
  return (
    <div className="header flex justify-between items-center mb-5">
      <div
        className="flex"
        onClick={() =>
          tabValue && pathName
            ? navigate(`${pathName}`, { state: tabValue })
            : navigate(-1)
        }
      >
        <h3 className="text-sm font-medium tracking-wide text-textLight flex items-center cursor-pointer transition-all duration-300 hover:text-primaryText">
          <BackArrowIcon />
          Back to {title}
        </h3>
      </div>
    </div>
  );
};

export default BackButton;
