import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Loader from "../../components/loader/Loader";
import {
  DeleteIcon,
  EditIcon,
  ViewIcon,
} from "../../components/svgIcons/SvgIcons";
import { TableComponent } from "../../components/table/TableComponent";
import { DeleteModal, FormatDate } from "../../helpers/Util";
import {
  currentPageCount,
  currentPageSelector,
} from "../../redux/ducks/pagination.duck";
import { DeleteProject, GetProjects } from "../../services/ProjectService";
import Card from "../../wrapper/Card";

const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(true);
  const currentPage = useSelector(currentPageSelector);
  const [deleteId, setDeleteId] = useState<string | number>("");
  const [projectsData, setProjectsData] = useState<{
    data: [];
    totalPage: number;
    totalCount: number;
  }>({
    data: [],
    totalPage: 0,
    totalCount: 0,
  });

  useEffect(() => {
    dispatch(currentPageCount(1));
  }, []);

  useEffect(() => {
    getAllProjects(queryString);
  }, [currentPage]);

  let queryString = `?page=${currentPage}&limit=${10}`;

  const columnData = [
    {
      header: "Name",
      name: "name",
      className: "",
      commonClass: "",
      option: {
        sort: true,
      },
    },
    {
      header: "Created At",
      name: "createdAt",
      className: "",
      commonClass: "",
      option: {
        sort: true,
      },
      cell: (props: { createdAt: string;  }) => {
        return (
      FormatDate(props.createdAt)
        );
      },
    },
    {
      header: "Action",
      commonClass: "right_action",
      cell: (props: { id: string}) => {
        return (
          <>
           <ViewIcon
              onClick={() => {
                navigate(`/projects/view/${props.id}`,{state:{...props}});
              }}
            />
            <EditIcon
              onClick={() => {
                navigate(`/projects/edit/${props.id}`,{state:{...props}});
              }}
            />
            {deleteId !== props.id ? (
              <DeleteIcon
                onClick={() => DeleteModal(props.id, deleteProject)}
              />
            ) : (
              <Loader size="w-5 h-5 mb-3 ml-2 text-[#E17446]" />
            )}
          </>
        );
      },
    },
  ];

  async function deleteProject(id: string) {
    setDeleteId(id);
    const response = await DeleteProject(id);
    const { response_type, message } = response.data;
    if (response_type === "success") {
      toast(message);
      await getAllProjects(queryString);
    } else {
      setDeleteId("");
      toast.error(message);
    }
  }

  async function getAllProjects(query: string) {
    setDeleteId("");
    deleteId !== "" && setLoader(true);

    const response = await GetProjects(query);
    if (response?.data?.responseData) {
      if (response?.data?.responseData?.lastPage === 1) {
        dispatch(currentPageCount(1));
      }
      setProjectsData({
        data: response?.data?.responseData?.data,
        totalCount: response.data.responseData.count,
        totalPage: response.data.responseData.lastPage,
      });
    }
    setLoader(false);
  }

  async function filterApiCallback(query: string) {
    setLoader(true);
    const response = await GetProjects(query);
    if (response?.data?.responseData) {
      setProjectsData({
        data: response?.data?.responseData.data,
        totalCount: response.data.responseData.count,
        totalPage: response.data.responseData.lastPage,
      });
    }
    setLoader(false);
  }

  return (
    
      <div className="gro_cat_listing">
        <Card
          title=""
          isButton={true}
          buttonText={"Add Project"}
          link="projects"
        >
          <TableComponent
            tableHeading={"Projects"}
            bodyData={projectsData?.data}
            headerData={columnData}
            dataPerPage={10}
            totalPage={projectsData.totalPage}
            search={true}
            paginationApiCb={filterApiCallback}
            pagination={true}
            filter={false}
            loader={loader}
          />
        </Card>
      </div>
  
  );
};

export default Projects;
