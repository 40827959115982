import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../../components/button/Button";
import InputField from "../../components/Input/InputField";
import { HidePassword, ShowPassword } from "../../components/svgIcons/SvgIcons";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../redux/ducks/siteLoader.duck";
import {
  removeResetPasswordToken,
  resetTokenSelector,
} from "../../redux/ducks/user.duck";
import { ResetPasswords } from "../../services/AuthServices";
import { ResetPasswordValidationSchema } from "../../validation/login/ResetPasswordValidation";

const ResetPassword = () => {
  const resetToken = useSelector(resetTokenSelector);
  const dispatch = useDispatch();
  const siteLoader = useSelector(siteLoaderSelector);
  const { state = {} } = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>({
    newPassword: false,
    confirmPassword: false,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ newPassword: string; confirmPassword: string }>({
    resolver: yupResolver(ResetPasswordValidationSchema),
    mode: "onChange",
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
  });
  useEffect(() => {
    if (!state?.responseData?.access_token) {
      navigate("/login");
    }
  }, []);

  const OnSubmit = async ({
    confirmPassword,
  }: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    dispatch(showLoader());

    const params = { password: confirmPassword };
    if (resetToken) {
      const response = await ResetPasswords(params, resetToken);
      const { response_type, message } = response.data;
      if (response_type === "success") {
        dispatch(removeResetPasswordToken());
        navigate("/login");
      } else {
        toast.error(message);
      }
    }
    dispatch(hideLoader());
  };
  return (
    <>
      <div className="text-center mb-8">
        <h1 className="text-3xl font-semibold mb-6">Reset Password</h1>
      </div>
      <form className="auth_form" onSubmit={handleSubmit(OnSubmit)}>
        <InputField
          control={control}
          name="newPassword"
          type={showPassword.newPassword ? "text" : "password"}
          placeholder="New Password"
          errors={errors}
          parentClass={"mb-4"}
          icon={
            <div
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  newPassword: !showPassword.newPassword,
                })
              }
              className="absolute right-4 top-4 cursor-pointer"
            >
              {showPassword.newPassword ? <ShowPassword /> : <HidePassword />}
            </div>
          }
        />
        <InputField
          control={control}
          name="confirmPassword"
          type={showPassword.confirmPassword ? "text" : "password"}
          placeholder="Confirm Password"
          errors={errors}
          parentClass={"mb-4"}
          icon={
            <div
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  confirmPassword: !showPassword.confirmPassword,
                })
              }
              className="absolute right-4 top-4 cursor-pointer"
            >
              {showPassword.confirmPassword ? (
                <ShowPassword />
              ) : (
                <HidePassword />
              )}
            </div>
          }
        />
        <Button
          buttonVariant={"primary"}
          text={"Save"}
          type={"submit"}
          className="text-white min-h-[48px] text-base"
          loader={siteLoader}
        />
      </form>
    </>
  );
};

export default ResetPassword;
