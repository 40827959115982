import * as Yup from "yup";

import {
  ConfirmPasswordValidation,
  NewPasswordValidation,
} from "./PasswordValidation";

export const ResetPasswordValidationSchema = Yup.object().shape({
  newPassword: NewPasswordValidation,
  confirmPassword: ConfirmPasswordValidation,
});
