import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";

import {
  IToastType,
  toastSelector,
  ToastShow,
} from "../../redux/ducks/toast.duck";

import "react-toastify/dist/ReactToastify.css";

const ToastNotification = () => {
  return (
    <ToastContainer
      className="!z-[9999999]"
      position="bottom-left"
      autoClose={2500}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      theme="dark"
    />
  );
};

const mapStateToProps = createStructuredSelector({
  toastMessage: toastSelector,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  removeToast: (data: IToastType) => dispatch(ToastShow(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ToastNotification);
