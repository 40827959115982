import { useLocation } from "react-router-dom";

import OTPInput from "../../components/otpInput/Index";

const OtpVerify = () => {
  const { state = {} } = useLocation();

  return (
    <>
      <OTPInput
        email={state?.data?.email}
        autoFocus
        isNumberInput
        length={6}
        onChangeOTP={() => {}}
      />
    </>
  );
};

export default OtpVerify;
