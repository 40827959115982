import React from "react";
import { Link } from "react-router-dom";

const Wrapper = ({ children }: { children: React.ReactChild }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-224BB5/[0.1] relative lg:p-0 px-5 ">
      <Link
        to="#"
        className="absolute top-7 lg:left-7 left-1/2 lg:translate-x-0 -translate-x-1/2 2xl:w-[250px] w-[200px] bg-blue-600 p-3 rounded-lg"
      >
        <img
          src={`/images/Logo.png`}
          className="w-full h-full object-contain"
          alt=""
        />
      </Link>
      <div
        className="2xl:my-7 mt-28 mb-7 relative before:bg-auth1 lg:before:block before:hidden xl:before:w-300px before:w-250px before:h-full before:absolute before:bottom-0 xl:before:right-[-300px] before:right-[-250px] before:bg-no-repeat before:bg-contain before:bg-[bottom_110px_right_-40px] 
        lg:after:block after:hidden after:bg-auth2 xl:after:w-300px after:w-250px after:h-full after:absolute after:bottom-0 xl:after:left-[-300px] after:left-[-250px] after:bg-no-repeat after:bg-contain after:bg-[bottom_110px_left_-40px] 
  "
      >
        <div className="xl:w-550px lg:w-450px w-full shadow-shadow1 xl:px-12 px-10 xl:py-16 py-12 lg:rounded-40px rounded-3xl bg-white ">
          {children}
        </div>
        <div className="text-center mt-10">
          <span>
            Copyright © Esparkbiz 2024
          </span>
        </div>
      </div>
    </div>
  );
};
export default Wrapper;
