import { FC, ReactElement } from "react";
import {
  Controller,
  FieldErrors,
  FieldName,
  FieldValues,
} from "react-hook-form";
import PhoneInput from "react-phone-input-2";

export type InputProps<TFieldValues extends FieldValues = FieldValues> = {
  register: any;
  errors: FieldErrors<TFieldValues> | any;
  name: FieldName<TFieldValues>;
  placeholder?: string;
  label: string;
  type: "text" | "email" | "number" | "password";
  parentClass?: string;
  disabled?: boolean;
};

export type ControlledInputProps<
  TFieldValues extends FieldValues = FieldValues
> = {
  control: any;
  errors: FieldErrors<TFieldValues> | any;
  className?: string;
  name: FieldName<TFieldValues>;
  placeholder?: string;
  label?: string;
  parentClass?: string;
  type: "text" | "email" | "number" | "password" | "file";
  disable?: boolean;
  required?: boolean;
  row?: number;
  icon?: ReactElement;
};

export const InputField: FC<ControlledInputProps> = ({
  name,
  disable,
  type,
  required,
  label,
  control,
  icon,
  placeholder,
  className,
  errors,
  parentClass = {},
}) => {
  return (
    <div className={`input-item relative ${parentClass && parentClass}`}>
      {label && <label className="block mb-2 text-15px">{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <input
            type={type}
            onKeyDown={(evt) => {
              type === "number" &&
                ["e", "E", "+", "-"].includes(evt.key) &&
                evt.preventDefault();
            }}
            placeholder={placeholder ? placeholder : ""}
            disabled={disable}
            className={`
            ${errors[name] ? "border-red-500 " : "border-inputborder "} 
            ${className ? className : ""}
            w-full placeholder:text-zinc-500 border border-slate-200 py-2 min-h-[48px] px-4 focus:outline-none rounded-lg`}
            {...field}
          />
        )}
      />
      {icon && icon}
      {errors[name] && (
        <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
          {errors[name]?.message}
        </span>
      )}
    </div>
  );
};


export default InputField;

export type ControlledTelInputProps<
  TFieldValues extends FieldValues = FieldValues
> = {
  control: any;
  errors: FieldErrors<TFieldValues> | any;
  name: FieldName<TFieldValues>;
  parentClass?: string;
  required?: boolean;
  setPhone?: any;
  value: any;
};

export const TelInput: FC<ControlledTelInputProps> = ({
  name,
  required,
  control,
  errors,
  parentClass = {},
  setPhone,
  value
}) => {

  let validPhoneNumber = false;
  const validatePhoneNumber = (
    inputNumber: string,
    country: any,
    isDirty: boolean,
    phoneLength: number
  ) => {
    if (isDirty) {
      if (
        inputNumber &&
        inputNumber?.replace(country.dialCode, "")?.trim() === ""
      ) {
        validPhoneNumber = false;
        return false;
      } else if (inputNumber.length < phoneLength) {
        validPhoneNumber = false;
        return false;
      }
      validPhoneNumber = true;
      return true;
    }
    validPhoneNumber = false;
    return false;
  };
  return (
    <div className={`input-item relative ${parentClass && parentClass}`}>
      <Controller
        name={name}
        control={control}
        render={(props) => {
          return (
            <PhoneInput
              onChange={(data: any, value: any) => {
                props.field.onChange(data);
                setPhone({
                  code: `+${value?.dialCode}`,
                  no: data?.slice(value.dialCode?.length),
                });
              }}
              inputProps={{
                id: name,
                name,
                required,
                autoComplete: "none",
              }}
              country={"us"}
              value={value}
              isValid={(inputNumber, country: any, countries) => {
                const phoneLength = Math.ceil(
                  (
                    countries.filter(
                      (val: any) => val.dialCode === country.dialCode
                    )[0] as any
                  )?.format.length / 2
                );
                return validatePhoneNumber(
                  inputNumber,
                  country,
                  props.formState.isDirty,
                  phoneLength
                );
              }}
              specialLabel=""
            />
          );
        }}
        rules={{
          required,
          validate: () => validPhoneNumber || errors?.errorMessage?.validate,
        }}
      />
      {errors[name] && (
        <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
          {errors[name]?.message}
        </span>
      )}
    </div>
  );
};
