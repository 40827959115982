import React from "react";
import { useDispatch } from "react-redux";

import { setToggleModal } from "../../redux/ducks/modal.duck";
import Button from "../button/Button";
import { CloseIcon } from "../svgIcons/SvgIcons";

const Modal = ({
  modalWidth,
  Title,
  children,
  isButton,
  toggle,
}: {
  modalWidth?: string;
  Title?: string;
  children?: React.ReactNode;
  isButton?: boolean;
  toggle?: string;
}) => {
  const dispatch = useDispatch();
  return (
    <div className="fixed outline-none  shadow-[0px_22px_70px_100vw_rgba(0,0,0,0.30)] rounded-lg left-1/2 -translate-x-1/2 top-5 z-20 sm:w-auto w-full">
      <div
        className={`w-auto pointer-events-none ${
          modalWidth
            ? `max-w-[${modalWidth}px]`
            : "sm:max-w-[500px] max-w-none sm:min-w-[500px] min-w-full"
        }`}
      >
        <div className="border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 className="text-xl font-medium leading-normal text-gray-800">
              {Title}
            </h5>
            <span
              onClick={() => dispatch(setToggleModal(null))}
              className="w-6 h-6 cursor-pointer"
            >
              <CloseIcon />
            </span>
          </div>
          <div className="modal-body relative p-5">{children}</div>
          {isButton && (
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <span className="mr-2">
                <Button buttonVariant="" text="Close" type="button" />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
