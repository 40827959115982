import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "animate.css";

export const DeleteModal = (id: string, callBack: (id: string) => void) => {
  const alert = withReactContent(Swal);
  alert
    .fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonColor: "#E17446",
      cancelButtonColor: "#999",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
      showClass: {
        popup: "animate__animated animate__fadeInDown animate__faster",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp animate__faster",
      },
    })
    .then((result) => {
      if (result.isConfirmed) {
        callBack(id);
      }
    });
};
export const ResetModal = (
  modalText: string,
  submitText: string,
  callBack: () => void
) => {
  const alert = withReactContent(Swal);
  alert
    .fire({
      title: `${modalText}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonColor: "#3369FD",
      cancelButtonColor: "#999",
      confirmButtonText: `${submitText}`,
      reverseButtons: true,
      showClass: {
        popup: "animate__animated animate__fadeInDown animate__faster",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp animate__faster",
      },
    })
    .then((result) => {
      if (result.isConfirmed) {
        callBack();
      }
    });
};
export const FormatString = (str: string): string => {
  if (str?.length <= 0) return "";
  let onlyChar = str?.replace(/[^a-zA-Z0-9()]/g, " ");
  return onlyChar?.charAt(0)?.toUpperCase() + onlyChar?.slice(1);
};


export const FormatDate = (date: string | Date) => {
  if (!date) return "-";
  return moment(new Date(date)).format("YYYY-MM-DD");
};

export const FormatTime = (date: string | Date) => {
  if (!date) return "-";
  return moment(new Date(date)).format("LT");
};


export function findIdstoDelete(array1:any[], array2:any[]) {
  const notPresentIds = array1
      .filter(obj1 => !array2.some(obj2 => obj2.id === obj1.id))
      .map(obj => obj.id);

  return notPresentIds;
}


export function trimStringFromUrl(str:string){
  let newFile = str.split("/").pop();
  return newFile

  }