import { createSlice } from '@reduxjs/toolkit'

export interface IModalType {
 show:string|null

}

const initialState = {
    show:null
}

export const toggleModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setToggleModal: (state: IModalType, action: {payload:string|null}) => {
      state.show = action.payload
    },
  },
})

export const modalSelector = (state: { modal: IModalType }) => {
  return state.modal.show
}

const { actions, reducer } = toggleModalSlice

export const { setToggleModal } = actions

export default reducer
