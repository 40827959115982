import React from "react";

const Button = ({
  onlyDisable,
  type,
  text,
  className,
  parentClassName,
  onClickHandler,
  loader,
  buttonVariant,
  icon,
}: {
  type: "button" | "submit" | "reset" | undefined;
  text: string;
  onlyDisable?: boolean;
  className?: string;
  parentClassName?: string;
  onClickHandler?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loader?: boolean;
  buttonVariant: string;
  icon?: JSX.Element;
}): JSX.Element => {
  const getButtonClass = () => {
    let data;
    switch (buttonVariant) {
      case "primary":
        data = (
          <span className="absolute text-white left-0 top-0 w-full h-full rounded-5px bg-3369FD group-hover:bg-08162D group-hover:text-white border border-solid border-3369FD group-hover:border-08162D transition-all duration-300"></span>
        );
        break;
      case "primaryBorder":
        data = (
          <span className="absolute left-0 top-0 w-full h-full rounded-5px group-hover:bg-primaryColor border border-solid border-primaryColor transition-all duration-300 ml-3"></span>
        );
        break;
      case "primaryBlack":
        data = (
          <span className="absolute left-0 top-0 w-full h-full rounded-5px bg-primaryText group-hover:bg-primaryText/80 border border-solid border-primaryText group-hover:border-primaryText/80 transition-all duration-300 ml-3"></span>
        );
        break;
      case "primaryBlackBorder":
        data = (
          <span className="absolute left-0 top-0 w-full h-full rounded-5px bg-transparent group-hover:bg-08162D group-hover:border-08162D border border-solid border-3369FD transition-all duration-300"></span>
        );
        break;
      case "btnSearch":
        data = (
          <span className="absolute text-3369FD left-0 top-0 w-full h-full bg-transparent  border-l border-solid border-224BB5/[0.2] transition-all duration-300 hover:bg-slate-100"></span>
        );
        break;
    }
    return data;
  };
  return (
    <>
      <div
        className={`relative ml-3 ${parentClassName ? parentClassName : ""}`}
      >
        <button
          type={type}
          disabled={onlyDisable || loader}
          className={`${
            onlyDisable ? "opacity-50" : "opacity-1"
          } w-full  ease-linear duration-200 rounded-10px px-5 py-2  text-15px group ${
            className ? className : ""
          }`}
          onClick={onClickHandler}
        >
          {getButtonClass()}
          <span
            className={`block relative leading-18px mb-px  transition-all duration-300 ${
              buttonVariant === "primaryBlackBorder" &&
              "text-primaryText group-hover:text-white"
            } ${
              buttonVariant === "primaryBorder" &&
              "text-3369FD group-hover:text-white"
            } ${icon ? "inline-flex items-center" : ""}`}
          >
            {icon && <span className="mr-1">{icon}</span>}
            {text}
          </span>
          {loader && (
            <div
              className={`
            pl-3 transition-all duration-300
            ${buttonVariant === "primary" && "text-white"}
            ${
              buttonVariant === "primaryBorder" &&
              "text-primaryColor group-hover:text-white"
            }
            ${buttonVariant === "primaryBlack" && "text-white"}
            ${
              buttonVariant === "primaryBlackBorder" &&
              "text-primaryColor group-hover:text-white"
            }
            `}
            >
              <svg
                className={`w-4 h-4 animate-spin`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          )}
        </button>
      </div>
    </>
  );
};

export default Button;
