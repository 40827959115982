import { Control, FieldErrors, useFieldArray } from "react-hook-form";

import Button from "../../components/button/Button";
import InputField from "../../components/Input/InputField";
import CustomSelect from "../../components/select/CustomSelect";
import { DeleteIcon, PlusIcon } from "../../components/svgIcons/SvgIcons";
import { enviornmentTypeData } from "../../constants";
import { IProjectForm } from "../../interface/project/ProjectInterface";

export type Props = {
  control?: Control<IProjectForm>;
  errors?: FieldErrors<IProjectForm>;
};

const ProjectCredentialFields = ({ control, errors }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "credentials",
  });

  return (
    <>
      {fields.map((item, index) => {
        return index === 0 ? (
          <div
            key={item.id}
            className="main_section bg-224BB5/[0.07]  p-5 rounded-xl mb-3"
          >
            <div className="mb-4  flex flex-wrap items-center justify-between">
              <h1 className="text-lg font-medium">Credential Section</h1>
              {fields.length>1&&<span>
                <DeleteIcon onClick={() => remove(index)} />
              </span>}
            </div>
            <div className="mb-4 flex">
            <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
              <CustomSelect
                 name={`credentials.${index}.enviornmentType`}
                label="Project Enviornment Type"
                labelKey="label"
                valueKey="value"
                options={enviornmentTypeData}
                control={control}
                formatLabel
                parentClass={""}
              />
                {errors?.credentials &&
                errors?.credentials[index] &&
                errors?.credentials[index]?.enviornmentType && (
                  <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                    {`${errors?.credentials[index]?.enviornmentType?.message}`}
                  </span>
                )}
                </div>
                <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
           <InputField
                name={`credentials.${index}.stakeholder`}
                control={control}
                type="text"
                placeholder="Enter Stakeholder"
                errors={errors}
                label="Stakeholder"
                parentClass={""}
              />
              {errors?.credentials &&
                errors?.credentials[index] &&
                errors?.credentials[index]?.stakeholder && (
                  <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                    {`${errors?.credentials[index]?.stakeholder?.message}`}
                  </span>
                )}
           </div>
           <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
           <InputField
                name={`credentials.${index}.url`}
                control={control}
                type="text"
                placeholder="Enter Url"
                errors={errors}
                label="Url"
                parentClass={""}
              />
              {errors?.credentials &&
                errors?.credentials[index] &&
                errors?.credentials[index]?.url && (
                  <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                    {`${errors?.credentials[index]?.url?.message}`}
                  </span>
                )}
           </div>
           <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
           <InputField
                name={`credentials.${index}.userName`}
                control={control}
                type="text"
                placeholder="Enter UserName Or Email"
                errors={errors}
                label="Username Or Email"
                parentClass={""}
              />
              {errors?.credentials &&
                errors?.credentials[index] &&
                errors?.credentials[index]?.userName && (
                  <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                    {`${errors?.credentials[index]?.userName?.message}`}
                  </span>
                )}
           </div>
           <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
           <InputField
                name={`credentials.${index}.password`}
                control={control}
                type="text"
                placeholder="Enter Password"
                errors={errors}
                label="Password"
                
              />
              {errors?.credentials &&
                errors?.credentials[index] &&
                errors?.credentials[index]?.password && (
                  <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                    {`${errors?.credentials[index]?.password?.message}`}
                  </span>
                )}
                </div>
            </div>
          </div>
        ) : (
          <div
            key={item.id}
            className="sub_section bg-224BB5/[0.07]  p-5 rounded-xl mb-3"
          >
            <div className="mb-4 flex flex-wrap items-center justify-between">
              <h1 className="text-lg font-medium">Credential Section</h1>
              <span>
                <DeleteIcon onClick={() => remove(index)} />
              </span>
            </div>
            <div className="mb-4  flex">
            <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
              <CustomSelect
              name={`credentials.${index}.enviornmentType`}
                label="Project enviornment Type"
                labelKey="label"
                valueKey="value"
                options={enviornmentTypeData}
                control={control}
                parentClass=""
                formatLabel
              />
               {errors?.credentials &&
                errors?.credentials[index] &&
                errors?.credentials[index]?.enviornmentType && (
                  <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                    {`${errors?.credentials[index]?.enviornmentType?.message}`}
                  </span>
                )}
                </div>
                <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
           <InputField
                name={`credentials.${index}.stakeholder`}
                control={control}
                type="text"
                placeholder="Enter Stakeholder"
                errors={errors}
                label="Stakeholder"
                parentClass={""}
              />
              {errors?.credentials &&
                errors?.credentials[index] &&
                errors?.credentials[index]?.stakeholder && (
                  <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                    {`${errors?.credentials[index]?.stakeholder?.message}`}
                  </span>
                )}
           </div>
           <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
           <InputField
                name={`credentials.${index}.url`}
                control={control}
                type="text"
                placeholder="Enter Url"
                errors={errors}
                label="Url"
                parentClass={""}
              />
              {errors?.credentials &&
                errors?.credentials[index] &&
                errors?.credentials[index]?.url && (
                  <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                    {`${errors?.credentials[index]?.url?.message}`}
                  </span>
                )}
           </div>
              <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
           <InputField
                name={`credentials.${index}.userName`}
                control={control}
                type="text"
                placeholder="Enter Username Or Email"
                errors={errors}
                label="Username or Email"
                parentClass={""}
              />
              {errors?.credentials &&
                errors?.credentials[index] &&
                errors?.credentials[index]?.userName && (
                  <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                    {`${errors?.credentials[index]?.userName?.message}`}
                  </span>
                )}
           </div>
           <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
           <InputField
                name={`credentials.${index}.password`}
                control={control}
                type="text"
                placeholder="Enter Password"
                errors={errors}
                label="Password"
                
              />
              {errors?.credentials &&
                errors?.credentials[index] &&
                errors?.credentials[index]?.password && (
                  <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                    {`${errors?.credentials[index]?.password?.message}`}
                  </span>
                )}
                </div>
            </div>

            <div></div>
          </div>
        );
      })}
      <div className="inline-block">
        <Button
          buttonVariant={"primary"}
          text="Add More Credentials"
          type="button"
          icon={<PlusIcon />}
          onClickHandler={() => {
            append({ userName: "", password: "", enviornmentType: "" ,stakeholder:"",url:""});
          }}
          className="text-white capitalize"
          parentClassName="!ml-0"
        />
      </div>
    </>
  );
};

export default ProjectCredentialFields;
