import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { siteLoaderSelector } from "../../redux/ducks/siteLoader.duck";
import { resetPasswordToken } from "../../redux/ducks/user.duck";
import { OtpVerification, ResendOTP } from "../../services/AuthServices";
import Button from "../button/Button";

import SingleInput from "./SingleInput";

export interface OTPInputProps {
  length: number;
  onChangeOTP: (otp: string) => void;

  autoFocus?: boolean;
  isNumberInput?: boolean;
  disabled?: boolean;
  email: string;
}

export function OTPInputComponent(props: OTPInputProps) {
  const {
    length,
    email,
    isNumberInput,
    autoFocus,
    disabled,
    onChangeOTP,
    ...rest
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siteLoader = useSelector(siteLoaderSelector);
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(""));

  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join("");
      onChangeOTP(otpValue);
    },
    [onChangeOTP]
  );
  useEffect(() => {
    if (!email) {
      navigate("/login");
    }
  }, []);

  const getRightValue = useCallback(
    (str: string) => {
      let changedValue = str;

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : "";
    },
    [isNumberInput]
  );

  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput)
        .split("");
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues]
  );

  const validateOPT = async () => {
    const finalOTP = otpValues.join("");
    const params = { otp: finalOTP, email: email, type: "FORGOT" };
    const response = await OtpVerification(params);
    const { response_type, responseData, message } = response.data;
    if (response_type === "success") {
      dispatch(resetPasswordToken(responseData?.access_token));
      navigate("/reset-password", { state: { responseData } });
    } else {
      toast(message);
    }
  };

  const resendOPT = async () => {
    const params = { email: email, type: "FORGOT" };
    const response = await ResendOTP(params);
    const { message } = response.data;
    toast(message);
  };

  return (
    <>
      <div className="text-center mb-8">
        <h1 className="text-3xl font-semibold mb-6">OTP Verification</h1>
        <div className="flex flex-col">
          <span className="text-lg">A One-Time Password has been sent to</span>
          <span className="font-normal">{email}</span>
        </div>
      </div>
      <div className="text-black px-3" {...rest}>
        {Array(length)
          .fill("")
          .map((_, index) => (
            <SingleInput
              key={`SingleInput-${index}`}
              type={isNumberInput ? "number" : "text"}
              focus={activeInput === index}
              value={otpValues && otpValues[index]}
              autoFocus={autoFocus}
              onFocus={handleOnFocus(index)}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onBlur={onBlur}
              onPaste={handleOnPaste}
              disabled={disabled}
            />
          ))}
      </div>

      <div className="flex justify-center text-center mt-8">
        <Button
          buttonVariant={"primary"}
          text={"Resend"}
          type={"submit"}
          onlyDisable={otpValues.join("").length !== 6}
          onClickHandler={resendOPT}
          parentClassName="mr-4"
          className="text-white min-h-[48px] text-base"
        />
        <Button
          buttonVariant={"primary"}
          onlyDisable={otpValues.join("").length !== 6}
          text={"Validate"}
          type={"submit"}
          onClickHandler={validateOPT}
          loader={siteLoader}
          parentClassName="!m-0"
          className="text-white min-h-[48px] text-base"
        />
      </div>
    </>
  );
}

const OTPInput = memo(OTPInputComponent);
export default OTPInput;
