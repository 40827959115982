import { Control, FieldErrors, useFieldArray } from "react-hook-form";

import Button from "../../components/button/Button";
import DocumentUpload from "../../components/documentUpload/DocumentUpload";
import InputField from "../../components/Input/InputField";
import { DeleteIcon, PlusIcon } from "../../components/svgIcons/SvgIcons";
import { IProjectForm } from "../../interface/project/ProjectInterface";

export type Props = {
  control?: Control<IProjectForm>;
  errors?: FieldErrors<IProjectForm>;
  setValue?: any;
};

const FigmaFields = ({ control, errors, setValue }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "figma",
  });

  return (
    <>
      {fields.map((item, index) => {
        return index === 0 ? (
          <div
            key={item.id}
            className="main_section bg-224BB5/[0.07]  p-5 rounded-xl mb-3"
          >
            <div className="mb-4  flex flex-wrap items-center justify-between">
              <h1 className="text-lg font-medium">Figma Section</h1>
              {fields.length > 1 && (
                <span>
                  <DeleteIcon onClick={() => remove(index)} />
                </span>
              )}
            </div>
            <div className="mb-4 flex">
              <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
                <InputField
                  name={`figma.${index}.stakeholder`}
                  control={control}
                  type="text"
                  placeholder="Enter Stakeholder"
                  errors={errors}
                  label="Stakeholder"
                  parentClass={""}
                />
                {errors?.figma &&
                  errors?.figma[index] &&
                  errors?.figma[index]?.stakeholder && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.figma[index]?.stakeholder?.message}`}
                    </span>
                  )}
              </div>
              <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
                <InputField
                  name={`figma.${index}.link`}
                  control={control}
                  type="text"
                  placeholder="Enter Figma Link"
                  errors={errors}
                  label="Figma Link"
                  parentClass={""}
                />
                {errors?.figma &&
                  errors?.figma[index] &&
                  errors?.figma[index]?.link && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.figma[index]?.link?.message}`}
                    </span>
                  )}
              </div>
            <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
              <DocumentUpload
                name={`figma.${index}.figmaFile`}
                control={control}
                placeholder="Select Figma PDF"
                errors={errors}
                label="Project Figma PDF"
                setvalue={setValue}
                parentClass=""
              />
               {errors?.figma &&
                  errors?.figma[index] &&
                  errors?.figma[index]?.figmaFile && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.figma[index]?.figmaFile?.message}`}
                    </span>
                  )}
            </div>
            </div>
          </div>
        ) : (
          <div
            key={item.id}
            className="sub_section bg-224BB5/[0.07]  p-5 rounded-xl mb-3"
          >
            <div className="mb-4 flex flex-wrap items-center justify-between">
              <h1 className="text-lg font-medium">Figma Section</h1>
              <span>
                <DeleteIcon onClick={() => remove(index)} />
              </span>
            </div>
            <div className="mb-4 flex">
              <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
                <InputField
                  name={`figma.${index}.stakeholder`}
                  control={control}
                  type="text"
                  placeholder="Enter Stakeholder"
                  errors={errors}
                  label="Stakeholder"
                  parentClass={""}
                />
                {errors?.figma &&
                  errors?.figma[index] &&
                  errors?.figma[index]?.stakeholder && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.figma[index]?.stakeholder?.message}`}
                    </span>
                  )}
              </div>
              <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
                <InputField
                  name={`figma.${index}.link`}
                  control={control}
                  type="text"
                  placeholder="Enter Figma Link"
                  errors={errors}
                  label="Figma Link"
                  parentClass={""}
                />
                {errors?.figma &&
                  errors?.figma[index] &&
                  errors?.figma[index]?.link && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.figma[index]?.link?.message}`}
                    </span>
                  )}
              </div>
              <div className="flex flex-col gap-1 xl:w-1/3 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
              <DocumentUpload
                name={`figma.${index}.figmaFile`}
                control={control}
                placeholder="Select Figma Pdf"
                errors={errors}
                label="Project Figma PDF"
                setvalue={setValue}
                parentClass={"xl:w-full lg:w-full w-full lg:px-3 px-0 mb-5"}
              />
                {errors?.figma &&
                  errors?.figma[index] &&
                  errors?.figma[index]?.figmaFile && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.figma[index]?.figmaFile?.message}`}
                    </span>
                  )}
            </div>
            </div>
           
          </div>
        );
      })}
      <div className="inline-block">
        <Button
          buttonVariant={"primary"}
          text="Add More Figma"
          type="button"
          icon={<PlusIcon />}
          onClickHandler={() => {
            append({
              stakeholder: "",
              link: "",
              figmaFile: '',
            });
          }}
          className="text-white capitalize"
          parentClassName="!ml-0 mb-2"
        />
      </div>
    </>
  );
};

export default FigmaFields;
