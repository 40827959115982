import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../../components/button/Button";
import InputField from "../../components/Input/InputField";
import { HidePassword, ShowPassword } from "../../components/svgIcons/SvgIcons";
import { SetSession } from "../../contexts/AuthContext";
import { ILoginForm } from "../../interface/login/LoginInterface";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../redux/ducks/siteLoader.duck";
import { setToken, setUser } from "../../redux/ducks/user.duck";
import { AdminLogin } from "../../services/AuthServices";
import { LoginValidationSchema } from "../../validation/login/LoginValidation";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const siteLoader = useSelector(siteLoaderSelector);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>({
    resolver: yupResolver(LoginValidationSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function OnSubmit(data: ILoginForm) {
    dispatch(showLoader()); 

    let params = {
      email: data.email,
      password: data.password,
    };

    const response = await AdminLogin(params);
    const { response_type, message } = response?.data;
    const responseData = response.data.responseData;
    if (response_type === "success") {
        dispatch(setToken(responseData?.access_token));
        SetSession(responseData?.access_token);
        dispatch(setUser({...responseData?.user}));
        navigate("/dashboard");
    } else {
      toast.error(message);
    }
    dispatch(hideLoader());
  }
  return (
    <>
      <div className="text-center mb-8">
        <h1 className="xl:text-3xl lg:text-2xl text-xl font-semibold mb-6">
          PM - Admin Login
        </h1>
        <p className="text-gray-700 xl:text-lg lg:text-base text-sm">
          Hey, Enter your details to get sign in <br /> to your account
        </p>
      </div>
      <form className="auth_form" onSubmit={handleSubmit(OnSubmit)}>
        <InputField
          name="email"
          control={control}
          type="text"
          placeholder="Enter Email"
          errors={errors}
          parentClass={"mb-4"}
        />
        <InputField
          name="password"
          type={showPassword ? "text" : "password"}
          control={control}
          placeholder="Enter Password"
          errors={errors}
          parentClass={"mb-4 relative"}
          icon={
            <div
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-4 top-4 cursor-pointer"
            >
              {showPassword ? <ShowPassword /> : <HidePassword />}
            </div>
          }
        />
        <Button
          buttonVariant={"primary"}
          text={"Sign in"}
          type={"submit"}
          parentClassName="!m-0"
          className="text-white min-h-[48px] text-base flex items-center justify-center"
          loader={siteLoader}
        />
        <div className="text-right mt-8">
          <Link
            to="/forgot-password"
            className="hover:text-3369FD ease-linear duration-200"
          >
            forgot password ?
          </Link>
        </div>
      </form>
    </>
  );
};

export default Login;
