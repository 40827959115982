 export const enviornmentTypeData = [
    {
      label: "DEVELOPMENT",
      value: "DEVELOPMENT",
    },
    {
      label: "STAGING",
      value: "STAGING",
    },
    {
      label: "TESTING",
      value: "TESTING",
    },
    {
      label: "QA",
      value: "QA",
    },
    {
      label: "DEMO",
      value: "DEMO",
    },
    {
      label: "PRODUCTION",
      value: "PRODUCTION",
    },
  ];