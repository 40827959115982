import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import LeftSidebar from "../components/leftSideBar/LeftSideBar";
import Header from "../components/wrapper/Header";
import ChangePasswordModal from "../pages/userProfile/ChangePasswordModal";
import EditProfileModal from "../pages/userProfile/EditProfileModal";
import { modalSelector } from "../redux/ducks/modal.duck";
import { tokenSelector } from "../redux/ducks/user.duck";

const LayOut: React.FC = () => {
  const toggleModal = useSelector(modalSelector);
  const token = useSelector(tokenSelector);

  return (
    <div>
      {token ? (
        <div className="flex flex-wrap">
          <LeftSidebar />
          <div className="w-full lg:pl-[270px] pl-0 min-h-screen bg-white ">
            <div className="h-full bg-224BB5/[0.1] lg:rounded-l-3xl rounded-none lg:p-7 pt-24 px-4">
              <div className="main-wrapper">
                <Header />
                <Outlet />
              </div>
            </div>
          </div>
          {toggleModal === "changePasswordModal" && <ChangePasswordModal />}
          {toggleModal === "updateProfileModal" && <EditProfileModal />}
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default LayOut;
