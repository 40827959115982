import { useEffect, useRef } from 'react'

function UsePrevious<T>(value?: T) {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default UsePrevious
