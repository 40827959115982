import React, { useState } from "react";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";

import {
  currentPageCount,
  currentPageSelector,
} from "../../redux/ducks/pagination.duck";
import { siteLoaderSelector } from "../../redux/ducks/siteLoader.duck";
import Button from "../button/Button";
import Loader from "../loader/Loader";
import { FilterIcon, SearchIcon } from "../svgIcons/SvgIcons";

export const TableComponent = ({
  tableHeading,
  bodyData,
  headerData,
  dataPerPage,
  filter,
  totalPage,
  search,
  pagination,
  paginationApiCb,
  loader,
}: {
  tableHeading?: string;
  bodyData: {}[];
  filter?: string | boolean;
  headerData: {}[];
  dataPerPage?: number;
  totalPage: number;
  search?: boolean;
  pagination?: boolean;
  paginationApiCb: (searchString: string, pageNumber: number) => void;
  loader?: boolean;
}) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(currentPageSelector);
  const siteLoader = useSelector(siteLoaderSelector);
  const [searchText, setSearchText] = useState<string>("");
  const [tableHeaderData, setTableHeaderData] = useState<{}[]>([]);
  const [tableBodyData, setTablebodyData] = useState<{}[]>([]);
  const [forcePage, setForcePage] = useState<number>(1);
  const [prevSearch, setPrevSearch] = useState<string>("");
  const [filterToggle, setFilterToggle] = useState<boolean>(false);
  const [sortList, setSortList] = useState<{ [x: string]: string | null }>({
    name: null,
    createdAt: null,
  });

  const [openFilter, setOpenFilter] = useState<boolean>(false);

  useEffect(() => {
    if (bodyData) {
      setTablebodyData(bodyData);
    }
    if (headerData) {
      setTableHeaderData(headerData);
    }
    const loadTime = setTimeout(() => {}, 1000);
    return () => {
      clearTimeout(loadTime);
    };
  }, [bodyData, headerData, totalPage, dataPerPage]);

  useEffect(() => {
    const loadTime = setTimeout(() => {}, 1000);
    return () => {
      clearTimeout(loadTime);
    };
  }, []);

  function handlePageClick({ selected }: { selected: number }) {
    dispatch(currentPageCount(selected + 1));
    setForcePage(selected + 1);
  }
  let queryString = "?";

  async function clearSearch() {
    setPrevSearch("");
    setSearchText("");
    setForcePage(0);
    queryString = queryString + `&search=${""}`;
    await paginationApiCb(queryString, currentPage);
  }

  const handleSearch = async () => {
    setForcePage(0);

    let search = searchText.trim();

    queryString = queryString + `&search=${search}`;

    if (search === prevSearch.trim()) return;
    else {
      setPrevSearch(search.trim());
      await paginationApiCb(queryString, currentPage);
    }
  };

  const sortTableData = (field: string) => {
    const regex = /^[0-9.%]+$/;
    if (filterToggle) {
      tableBodyData.sort(
        (a: { [key: string]: string }, b: { [key: string]: string }) => {
          let x = a[field];
          let y = b[field];

          if (regex.test(x) && regex.test(x)) {
            x = a[field];
            y = b[field];
          } else {
            x = a[field]?.toLowerCase();
            y = b[field]?.toLowerCase();
          }
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        }
      );
    } else {
      tableBodyData.sort(
        (b: { [key: string]: string }, a: { [key: string]: string }) => {
          let x = a[field];
          let y = b[field];

          if (regex.test(x) && regex.test(x)) {
            x = a[field];
            y = b[field];
          } else {
            x = a[field]?.toLowerCase();
            y = b[field]?.toLowerCase();
          }
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        }
      );
    }
  };
  return (
    <div className="data-table-wrapper ">
      {search ? (
        <>
          <div className="data-table-search w-full md:mb-6 mb-3">
            <div className="md:flex block justify-between items-center">
              <div className="md:mb-0 mb-3">
                {tableHeading && (
                  <>
                    <h4 className="text-lg font-medium">
                      <span className="inline-block">
                        {typeof tableHeading === "string"
                          ? tableHeading
                          : tableHeading}
                      </span>
                    </h4>
                  </>
                )}
              </div>

              <div className="flex flex-wrap items-center justify-end">
                <div className="input-wrapper w-[210px] md:w-[250px] 1200:w-[320px] relative">
                  <input
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchText(e.target.value);
                    }}
                    value={searchText}
                    placeholder="Search"
                    className="bg-white border border-224BB5/[0.2] text-gray-900 text-15px rounded-5px block w-full py-2 pl-4 pr-16 outline-none min-h-[39px]"
                  />
                  <span
                    title="Cancel"
                    onClick={clearSearch}
                    className={`${
                      !prevSearch.trim() ? "hidden" : "block"
                    } absolute top-[12px] right-11 items-center justify-center cursor-pointer ml-2 w-5 h-5  text-primaryColor rounded-md  transition-all duration-300`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                  <Button
                    buttonVariant="btnSearch"
                    text=""
                    onClickHandler={() => {
                      handleSearch();
                    }}
                    icon={<SearchIcon />}
                    type="button"
                    className="search_button !pl-3 !pr-2 py-[11px] flex items-center justify-center !bg-transparent"
                    parentClassName="!absolute right-[1px] top-[1px] bottom-[1px]"
                  />
                </div>
                {filter && (
                  <div className="filter-wrapper">
                    <Button
                      buttonVariant="primary"
                      text="Filter"
                      onClickHandler={() => {
                        setOpenFilter(!openFilter);
                      }}
                      icon={<FilterIcon />}
                      type="button"
                      className="text-white"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <div className="table-wrapper">
        <div className="table-inner overflow-x-auto pb-4">
          <table className="w-full text-left border-separate border-spacing-y-2 whitespace-nowrap">
            <thead className="">
              <tr>
                {tableHeaderData.map((val: any, index) => {
                  return (
                    <th
                      key={val.header + index}
                      className={` ${
                        val.className
                          ? val.className
                          : "bg-3369FD/[0.16] py-4 px-5 text-08162D font-medium first:rounded-tl-md last:rounded-tr-md"
                      } ${val.commonClass ? val.commonClass : ""}`}
                    >
                      <div className="relative flex items-center select-none">
                        <div
                          className="flex cursor-pointer select-none"
                          onClick={(e: React.MouseEvent<HTMLElement>) => {
                            setFilterToggle(!filterToggle);
                            val?.option?.sort && sortTableData(val.name);
                            val?.option?.sort &&
                              setSortList({
                                [val.name]: sortList[`${val.name}`]
                                  ? null
                                  : val.name,
                              });
                          }}
                        >
                          <p className="mr-2 select-none">{val.header}</p>
                          {val?.option?.sort ? (
                            <span className={`up-arrow select-none opacity-50`}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={3}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                {sortList[`${val.name}`] === val.name ? (
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18"
                                  />
                                ) : (
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3"
                                  />
                                )}
                              </svg>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {(loader || siteLoader) && (
                <tr>
                  <td className="" colSpan={tableHeaderData.length}>
                    <div className="py-6 text-center">
                      <Loader size="w-8 h-8 text-[#E17446]" />
                    </div>
                  </td>
                </tr>
              )}
              {!(loader || siteLoader) && tableBodyData.length === 0 && (
                <tr>
                  <td className="" colSpan={tableHeaderData.length}>
                    <div className="py-6 text-center bg-white rounded-10px border">
                      <img
                        src={`/images/no-data.png`}
                        className="w-[150px] m-auto mb-4"
                        alt=""
                      />
                      <span>No Data Found</span>
                    </div>
                  </td>
                </tr>
              )}
              {!(loader || siteLoader) &&
                tableBodyData &&
                tableBodyData.length > 0 && (
                  <>
                    {tableBodyData.map(
                      (row: { [key: string]: string }, rowIndex: number) => {
                        return (
                          <tr
                            className={`${
                              row?.className ? row?.className : ""
                            }`}
                            key={rowIndex + 1}
                          >
                            {tableHeaderData.map(
                              (columnCell: any, colIndex: number) => {
                                return (
                                  <td
                                    key={colIndex + 1}
                                    className={` ${
                                      columnCell.className
                                        ? columnCell.className
                                        : "bg-fafafa py-3 px-5 text-stone-700"
                                    } ${
                                      columnCell.commonClass
                                        ? columnCell.commonClass
                                        : ""
                                    }`}
                                    onClick={(
                                      event: React.MouseEvent<HTMLElement>
                                    ) =>
                                      columnCell.onclickEvent
                                        ? columnCell.onclickEvent(row.id)
                                        : null
                                    }
                                  >
                                    {columnCell.cell ? (
                                      columnCell.cell(row)
                                    ) : (
                                      <p>
                                        {row[columnCell.name]
                                          ? row[columnCell.name].length > 25
                                            ? row[columnCell.name].substr(
                                                0,
                                                25
                                              ) + "..."
                                            : row[columnCell.name]
                                          : "-"}
                                      </p>
                                    )}
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        );
                      }
                    )}
                  </>
                )}
            </tbody>
          </table>
        </div>
      </div>

      {pagination && totalPage > 1 && (
        <>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={totalPage}
            forcePage={forcePage === 0 ? forcePage : currentPage - 1}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </>
      )}
    </div>
  );
};
