import { AiOutlineLogout } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { SetSession } from "../../contexts/AuthContext";
import { ResetModal } from "../../helpers/Util";
import {
  hideSidebarResponsive,
  sidebarResponsiveSelector,
} from "../../redux/ducks/sidebarResponsive.duck";
import { removeToken, setUser } from "../../redux/ducks/user.duck";

import SubMenu from "./SubMenu";

const LeftSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sidebarResponsive = useSelector(sidebarResponsiveSelector);

  function logOut() {
    dispatch(removeToken());
    dispatch(setUser(null));
    SetSession(null);
    navigate("/login");
  }
  return (
    <div
      className={` w-[270px] fixed top-0 lg:left-0 -left-[270px] z-20 ${
        sidebarResponsive
          ? "active left-0 after:w-full after:h-full after:fixed after:top-0 after:bottom-0 after:left-0 after:right-0 after:bg-white/[0.1] after:backdrop-blur"
          : ""
      }`}
    >
      <div className="w-full h-screen bg-white px-6 py-10 relative z-[1]">
        <div className="lg:mb-16 mb-7">
          <NavLink to="/dashboard" className="block">
            <img
              src={`/images/Logo.png`}
              className="w-full h-full object-contain bg-blue-600 p-3 rounded-lg"
              alt=""
            />
          </NavLink>
        </div>
        <span
          className="lg:hidden block close_icon w-4 h-10 absolute right-0 top-2 bg-3369FD/[0.16] rounded-tl-lg rounded-bl-lg after:w-2.5 after:h-2.5 after:absolute after:left-1.5 after:top-1/2 after:-translate-y-1/2 after:rotate-45 after:border-2 after:border-3369FD after:border-t-0 after:border-r-0"
          onClick={() => dispatch(hideSidebarResponsive())}
        ></span>
        <div>
          <div>
            <div className="min-h-[calc(100vh_-_240px)] relative pb-16">
              <SubMenu />
              <Link
                to="#"
                className="flex justify-center items-center px-4 py-3 rounded-10px border border-slate-300 text-slate-500 text-15px ease-linear duration-200 hover:bg-224BB5/[0.1] hover:text-08162D absolute w-full left-0 bottom-0"
                onClick={() => {
                  ResetModal("Logout ?", "Logout", logOut);
                }}
              >
                <AiOutlineLogout size={22} />
                <span className="inline-block ml-3">Logout</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;
