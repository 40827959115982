import { Controller, FieldErrors } from "react-hook-form";

interface TextAreaProps {
  name: string;
  label: string;
  placeholder?: string;
  errors: FieldErrors | any;
  className?: string;
  parentClass?: string;
  control: any;
  row?:number
}

const TextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  placeholder,
  className,
  errors,
  parentClass = {},
  control,
  row=5 
}) => {


  return (
    <div className={`input-item relative ${parentClass && parentClass}`}>
      {label && <label htmlFor={name} className="block mb-2 text-15px">{label}</label>}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <textarea
            {...field}
            rows={row}
            placeholder={placeholder ?? ""}
            className={`
            ${errors[name] ? "border-red-500 " : "border-inputborder "} 
            ${className ?? ""}
            w-full placeholder:text-zinc-500 border border-slate-200 py-2 min-h-[48px] px-4 focus:outline-none rounded-lg`}
          />
        )}
      />

      {errors[name] && (
        <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
          {errors[name]?.message}
        </span>
      )}
    </div>
  );
};

export default TextArea;
