import AddCategory from "../pages/category/AddCategory";
import Categories from "../pages/category/Categories";
import Dashboard from "../pages/dashboard/Dashboard";
import ForgotPassword from "../pages/login/ForgotPassword";
import Login from "../pages/login/Login";
import OtpVerify from "../pages/login/OtpVerify";
import ResetPassword from "../pages/login/ResetPassword";
import notFound from "../pages/notFound/Index";
import AddEditProject from "../pages/project/AddEditProject";
import Projects from "../pages/project/Projects";
import ViewProjectDetail from "../pages/project/ViewProjectDetail";


export const AuthRoutes = [
  {
    id: 1,
    path: "/login",
    name: "Login",
    element: Login,
  },
  {
    id: 2,
    path: "/forgot-password",
    name: "Forgot Password",
    element: ForgotPassword,
  },
  {
    id: 3,
    path: "/otp-verification",
    name: "Otp Verification",
    element: OtpVerify,
  },
  {
    id: 4,
    path: "/reset-password",
    name: "Reset Password",
    element: ResetPassword,
  },
];

export const RoutesPath = [
  { id: 1, path: "/dashboard", name: "Dashboard", element: Dashboard },
  { id: 2, path: "/categories", name: "Categories", element: Categories },
  {
    id: 3,
    path: "/categories/add",
    name: "Categories",
    element: AddCategory,
  },
  {
    id: 4,
    path: "/categories/edit/:id",
    name: "Categories",
    element: AddCategory,
  },
  {
    id: 5,
    path: "/projects",
    name: "Project",
    element: Projects,
  },
  {
    id: 6,
    path: "/projects/add",
    name: "Project Add",
    element: AddEditProject,
  },

  {
    id: 7,
    path: "/projects/edit/:id",
    name: "Project Edit",
    element: AddEditProject,
  },
  {
    id: 8,
    path: "/projects/view/:id",
    name: "Project View",
    element: ViewProjectDetail,
  },
  {
    id: 9,
    path: "*",
    name: "Not Found",
    element: notFound,
  },
];
