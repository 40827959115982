import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../../components/button/Button";
import InputField from "../../components/Input/InputField";
import CustomSelect from "../../components/select/CustomSelect";
import TextArea from "../../components/textArea/TextArea";
import VideoUpload from "../../components/videoUpload/VideoUpload";
import { enviornmentTypeData } from "../../constants";
import {
  findIdstoDelete,
  ResetModal,
  trimStringFromUrl,
} from "../../helpers/Util";
import { IProjectForm } from "../../interface/project/ProjectInterface";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../redux/ducks/siteLoader.duck";
import { GetCategories } from "../../services/CategoriesServices";
import { AddProject, UpdateProject } from "../../services/ProjectService";
import { ProjectValidationSchema } from "../../validation/project/projectValidation";
import Card from "../../wrapper/Card";

import FigmaFields from "./FigmaFields";
import ProjectCredentialFields from "./ProjectCredentialFields";
import VideoSectionFileds from "./VideoSectionFields";

const AddEditProject = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siteLoader = useSelector(siteLoaderSelector);
  const [categoryData, setCategoryData] = useState<{}[]>([]);
  let queryString = `?all=true`;

  useEffect(() => {
    GetAllCategories(queryString);
  }, []);

  async function GetAllCategories(query: string) {
    const response = await GetCategories(query);
    if (response?.data?.responseData) {
      setCategoryData(response?.data?.responseData?.data);
    }
  }

  useEffect(() => {
    if (location?.state !== null && categoryData?.length > 0) {
      const tempcred: any = [];
      const tempfigma: any = [];
      const tempVideoSetion: any = [];
      if (location.state.credentials.length > 0) {
        location.state?.credentials.forEach((creds: any) => {
          const objtoPush = {
            ...creds,
            enviornmentType: enviornmentTypeData.find(
              (items: any) => items.value === String(creds.enviornmentType)
            ),
          };
          delete objtoPush.projectId;
          delete objtoPush.createdAt;
          delete objtoPush.updatedAt;
          delete objtoPush.deletedAt;
          tempcred.push(objtoPush);
        });

        if (location.state.figma.length > 0) {
          location.state?.figma.forEach((item: any) => {
            const objtoPush = {
              ...item,
              figmaFile: item?.figmaFile
                ? `${trimStringFromUrl(item?.figmaFile)}`
                : null,
            };
            delete objtoPush.projectId;
            delete objtoPush.createdAt;
            delete objtoPush.updatedAt;
            delete objtoPush.deletedAt;
            delete objtoPush.figmaFileUpdatedAt;
            tempfigma.push(objtoPush);
          });
        }
        if (location.state.videoSection.length > 0) {
          location.state?.videoSection.forEach((item: any) => {
            const objtoPush = {
              ...item,
              video: item?.stakeholderVideo
                ? `${process.env.REACT_APP_API_URL}/${item?.stakeholderVideo}`
                : null,
            };
            delete objtoPush.projectId;
            delete objtoPush.createdAt;
            delete objtoPush.updatedAt;
            delete objtoPush.deletedAt;
            delete objtoPush.stakeholderVideo;
            delete objtoPush.stakeholderVideoUpdatedAt;
            tempVideoSetion.push(objtoPush);
          });
        }
      }
      reset({
        ...location?.state,
        name: location?.state?.name,
        description: location.state?.description,
        categoryId: categoryData.find(
          (items: any) => items.id === Number(location?.state?.categoryId)
        ),
        video: location?.state?.video
          ? `${process.env.REACT_APP_API_URL}/${location?.state?.video}`
          : null,
        credentials: tempcred,
        figma: tempfigma,
        videoSection: tempVideoSetion,
      });
    }
  }, [location.state, categoryData]);

  let defaultValue = {
    categoryId: "",
    name: "",
    description: "",
    video: "",
    eSparkPortfolioLink: "",
    gumletVideoURL: "",
    frontEndTechnologies: "",
    backEndTechnologies: "",
    thirdPartyTechnologies: "",
    videoSection: [
      {
        stakeholder: "",
        videoName: "",
        video: null,
      },
    ],
    figma: [
      {
        stakeholder: "",
        link: "",
        figmaFile: null,
      },
    ],
    credentials: [
      {
        password: "",
        userName: "",
        enviornmentType: "",
        stakeholder: "",
        url: "",
      },
    ],
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IProjectForm>({
    resolver: yupResolver(ProjectValidationSchema),
    mode: "onChange",
    defaultValues: defaultValue,
  });

  //On submit
  const OnSubmit = async (data: IProjectForm) => {
    dispatch(showLoader());
    if (!id) {
      const reqObj = new FormData();
      reqObj.append("name", data.name);
      if (typeof data?.categoryId !== "string") {
        reqObj.append("categoryId", String(data.categoryId.id));
      }
      reqObj.append("frontEndTechnologies", data.frontEndTechnologies);
      reqObj.append("backEndTechnologies", data.backEndTechnologies);
      reqObj.append("thirdPartyTechnologies", data.thirdPartyTechnologies);
      reqObj.append("eSparkPortfolioLink", data.eSparkPortfolioLink);
      reqObj.append("gumletVideoURL", data.gumletVideoURL);
      reqObj.append("description", data.description);
      reqObj.append("video", data.video);
      const credArr: any = [];
      data?.credentials.forEach((creds: any) => {
        const objtoPush = {
          ...creds,
          enviornmentType: creds.enviornmentType.value,
        };
        credArr.push(objtoPush);
      });
      credArr.length > 0 &&
        reqObj.append("credentials", JSON.stringify(credArr));

      const figmaArr: any = [];
      data?.figma.forEach((item: any, index: number) => {
        const objtoPush = {
          ...item,
        };
        reqObj.append(`figma[${index}]`, item?.figmaFile);
        delete objtoPush.figmaFile;
        figmaArr.push(objtoPush);
      });
      figmaArr.length > 0 && reqObj.append("figma", JSON.stringify(figmaArr));

      const videoSectionArr: any = [];
      data?.videoSection.forEach((item: any, index: number) => {
        const objtoPush = {
          ...item,
        };
        reqObj.append(`videoSection[${index}]`, item?.video);
        delete objtoPush.video;
        videoSectionArr.push(objtoPush);
      });
      videoSectionArr.length > 0 &&
        reqObj.append("videoSection", JSON.stringify(videoSectionArr));

      const response = await AddProject(reqObj);
      const { response_type, message } = response.data;
      if (response_type === "success") {
        toast.success(message);
        navigate("/projects");
      } else {
        toast.error(message);
      }
    } else {
      const reqObj = new FormData();
      reqObj.append("name", data.name);
      if (typeof data?.categoryId !== "string") {
        reqObj.append("categoryId", String(data.categoryId.id));
      }
      reqObj.append("frontEndTechnologies", data.frontEndTechnologies);
      reqObj.append("backEndTechnologies", data.backEndTechnologies);
      reqObj.append("thirdPartyTechnologies", data.thirdPartyTechnologies);
      reqObj.append("eSparkPortfolioLink", data.eSparkPortfolioLink);
      reqObj.append("gumletVideoURL", data.gumletVideoURL);

      reqObj.append("description", data.description);
      if (typeof data.video === "object") {
        reqObj.append("videoUpdated", "true");
        reqObj.append("video", data.video);
      } else {
        reqObj.append("video", location.state.video);
      }
      const credArr: any = [];
      data?.credentials.forEach((creds: any) => {
        const objtoPush = {
          ...creds,
          enviornmentType: creds.enviornmentType.value,
        };
        delete objtoPush.projectId;
        delete objtoPush.createdAt;
        delete objtoPush.updatedAt;
        delete objtoPush.deletedAt;
        credArr.push(objtoPush);
      });
      credArr && reqObj.append("credentials", JSON.stringify(credArr));
      const deleteCredsArr = findIdstoDelete(
        location?.state.credentials,
        credArr
      );
      deleteCredsArr?.length > 0 &&
        reqObj.append("deleteCreds", JSON.stringify(deleteCredsArr));

      const figmaArr: any = [];
      data?.figma.forEach((item: any, index: number) => {
        const objtoPush = {
          ...item,
          figmaFileUpdated: item?.figmaFile === "object" ? true : false,
        };
        if (typeof item.figmaFile === "object") {
          reqObj.append(`figma[${index}]`, item?.figmaFile);
        }
        delete objtoPush.figmaFile;
        figmaArr.push(objtoPush);
      });
      figmaArr.length > 0 && reqObj.append("figma", JSON.stringify(figmaArr));
      const deleteFigmaArr = findIdstoDelete(location?.state.figma, figmaArr);
      deleteFigmaArr?.length > 0 &&
        reqObj.append("deleteFigma", JSON.stringify(deleteFigmaArr));

      const videoArr: any = [];
      data?.videoSection.forEach((item: any, index: number) => {
        const objtoPush = {
          ...item,
          stakeholderVideoUpdated: item?.video === "object" ? true : false,
        };
        if (typeof item.video === "object") {
          reqObj.append(`videoSection[${index}]`, item?.video);
        }
        delete objtoPush.video;
        videoArr.push(objtoPush);
      });
      videoArr.length > 0 &&
        reqObj.append("videoSection", JSON.stringify(videoArr));

      const deleteVideoSectionArr = findIdstoDelete(
        location?.state.figma,
        videoArr
      );

      deleteVideoSectionArr?.length > 0 &&
        reqObj.append(
          "deleteVideoSection",
          JSON.stringify(deleteVideoSectionArr)
        );
      const response = await UpdateProject(id, reqObj);
      const { response_type, message } = response.data;
      if (response_type === "success") {
        toast.success(message);
        navigate("/projects");
      } else {
        toast.error(message);
      }
    }
    dispatch(hideLoader());
  };

  return (
    <Card title={id ? "Update Project" : "Add Project"} link="">
      <form
        className="auth_form"
        encType="multipart/form-data"
        onSubmit={handleSubmit(OnSubmit)}
      >
        <div>
          <div className="xl:px-16 px-0">
            <h1 className="text-lg font-medium mb-5">Basic Details</h1>
            <div className="flex flex-wrap">
              <CustomSelect
                name="categoryId"
                label="Project Category"
                labelKey="name"
                valueKey="id"
                options={categoryData}
                control={control}
                errors={errors}
                formatLabel
                parentClass={"xl:w-1/2 lg:w-1/2 w-full lg:px-3 px-0 mb-5"}
              />
              <InputField
                name="name"
                control={control}
                type="text"
                placeholder="Enter Project Name"
                errors={errors}
                label="Project Name"
                parentClass={"xl:w-1/2 lg:w-1/2 w-full lg:px-3 px-0 mb-5"}
              />
            </div>
            <div>
              <VideoUpload
                name="video"
                control={control}
                placeholder="Enter Gumlet Video"
                errors={errors}
                label="Project Gumlet Video"
                setvalue={setValue}
                parentClass={"xl:w-full lg:w-full w-full lg:px-3 px-0 mb-5"}
              />
            </div>
            <div className="flex flex-col gap-1 w-full lg:px-3 px-0 mb-5">
              <InputField
                name="gumletVideoURL"
                control={control}
                type="text"
                placeholder="Enter Url"
                errors={errors}
                label="Gumlet Video URL"
                parentClass={""}
              />
            </div>
            <div className="flex flex-wrap">
              <TextArea
                name="description"
                control={control}
                placeholder="Enter Description"
                errors={errors}
                label="Project Description"
                parentClass={"xl:full lg:w-full w-full lg:px-3 px-0 mb-5"}
              />
              <InputField
                name="eSparkPortfolioLink"
                control={control}
                type="text"
                placeholder="Enter eSpark Portfolio Page URL"
                errors={errors}
                label="eSpark Portfolio Page URL"
                parentClass={"xl:w-full lg:w-full w-full lg:px-3 px-0 mb-5"}
              />
            </div>
          </div>
          <div className="xl:px-16 px-0">
            <h1 className="text-lg font-medium mb-5">Project Technologies</h1>
            <div className="flex flex-wrap">
              <TextArea
                name="frontEndTechnologies"
                control={control}
                placeholder="Enter Front-End Technologies"
                errors={errors}
                label="Front-End Technlogies"
                parentClass={"xl:full lg:w-full w-full lg:px-3 px-0 mb-5"}
              />
              <TextArea
                name="backEndTechnologies"
                control={control}
                placeholder="Enter Back-End Technologies + Database"
                errors={errors}
                label="Back-End Technlogies + Database"
                parentClass={"xl:full lg:w-full w-full lg:px-3 px-0 mb-5"}
              />
              <TextArea
                name="thirdPartyTechnologies"
                control={control}
                placeholder="Enter Third Party Technologies"
                errors={errors}
                label="Third Party Technologies"
                parentClass={"xl:full lg:w-full w-full lg:px-3 px-0 mb-5"}
              />
            </div>
          </div>
          <div className="xl:px-16 px-0">
            {/* figma */}
            <FigmaFields {...{ control, errors, setValue }} />
          </div>
          <div className="xl:px-16 px-0">
            {/* Video */}
            <VideoSectionFileds {...{ control, errors, setValue }} />
          </div>
          <div className="xl:px-16 px-0">
            <ProjectCredentialFields {...{ control, errors }} />
          </div>
          <div className="xl:px-16 px-0 mt-10">
            <div className="flex justify-end mt-5">
              <Button
                buttonVariant={"primaryBlackBorder"}
                text={"Reset"}
                type={"reset"}
                onlyDisable={!isDirty}
                onClickHandler={() => {
                  ResetModal("Are you sure?", "Reset", reset);
                }}
              />
              <Button
                buttonVariant={"primary"}
                text={"Submit"}
                type={"submit"}
                // onlyDisable={!isDirty}
                loader={siteLoader}
                className="text-white flex items-center"
              />
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default AddEditProject;
