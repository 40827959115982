import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import toggleModalReducer from "./ducks/modal.duck";
import currentPageReducer from "./ducks/pagination.duck";
import sidebarResponsiveReducer from "./ducks/sidebarResponsive.duck";
import siteLoaderReducer from "./ducks/siteLoader.duck";
import toggleTabReducer from "./ducks/tab.duck";
import toastReducer from "./ducks/toast.duck";
import userReducer from "./ducks/user.duck";

const persistConfig = {
  key: "PM-ESPL",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  currentPage: currentPageReducer,
  user: userReducer,
  modal: toggleModalReducer,
  tab: toggleTabReducer,
  toast: toastReducer,
  siteLoader: siteLoaderReducer,
  sidebarResponsive: sidebarResponsiveReducer,
});

export default persistReducer(persistConfig, rootReducer);
