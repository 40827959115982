import { Dispatch, SetStateAction } from "react";
import { MdCancel } from "react-icons/md";
import ImageUploading, {
  ImageListType,
  ImageType,
} from "react-images-uploading";

interface ImageProps {
  images: ImageListType;
  setImages: Dispatch<SetStateAction<ImageType[]>>;
  multiple: boolean;
  label?: string;
  parentClass?: string;
}

function ImageUpload({
  images,
  setImages,
  multiple,
  label,
  parentClass,
}: ImageProps) {
  const maxNumber = 69;

  const onChange = (imageList: ImageListType) => {
    setImages(imageList);
  };

  return (
    <div className={`input-item relative ${parentClass && parentClass}`}>
      <ImageUploading
        multiple={multiple}
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        acceptType={["jpg", "png", "jpeg"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <>
            <label className="block mb-2 text-15px">{label ? label : ""}</label>
            <div
              className={`text-sm p-2.5 cursor-pointer rounded-lg border border-dashed border-gray-500 flex flex-wrap items-center min-h-[48px] ${
                multiple ? "" : "justify-center"
              }`}
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              <span
                className={`${
                  imageList.length > 0 ? "hidden" : "block text-center w-full"
                }`}
              >
                {"Select image OR drag and drop file here"}
              </span>
              {imageList?.length > 0 &&
                imageList.map((image, index) => (
                  <div
                    key={index}
                    className={`image-item ${multiple ? "m-1" : ""}`}
                  >
                    <div className="group relative w-24 h-24 bg-dddddd flex items-center justify-center rounded-xl">
                      <span className="absolute flex items-center justify-center bg-black/50 text-white text-2xl opacity-0 group-hover:opacity-100 w-full h-full left-0 top-0 rounded-xl transition-all duration-300">
                        <MdCancel
                          onClick={(e) => {
                            onImageRemove(index);
                            e.stopPropagation();
                          }}
                        />
                      </span>

                      <img
                        src={image.dataURL}
                        alt=""
                        className="w-full h-full object-contain object-center"
                        width={"100%"}
                        height={"100%"}
                        crossOrigin="anonymous"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </ImageUploading>
    </div>
  );
}
export default ImageUpload;
