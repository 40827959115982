import { AxiosPost, AxiosPut } from '../axios/Config'

export const AdminLogin = (data: object) => {
  return AxiosPost('/auth/login',data)
}

export const ForgotPasswords = (data: object) => {
  return AxiosPost('/auth/forgot-password', data)
}

export const OtpVerification = (data: object) => {
  return AxiosPost('/auth/otp-verification', data)
}

export const ResetPasswords = (data: object, token: string) => {
  return AxiosPost('/auth/set-password', data, { headers: { Authorization: `jwt ${token}` } })
}

export const ResendOTP = (data: object) => {
  return AxiosPost('/auth/send-otp', data)
}

export const UpdatePassword = (data:object) => {
  return AxiosPost(`/auth/change-password`,data)
}

 export const UpdateAdmin =  (id: number | string, data: object) => {
  return AxiosPut(`/user/${id}`, data)
}