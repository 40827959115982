import * as Yup from "yup";

export const CategoryValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  image: Yup.lazy((value) => {
    switch (typeof value) {
      case "string":
        return Yup.string();
      default:
        return Yup.mixed()
          .required("image is required")
    }
  }),
});
