const ProfileImage = ({
  profileImage,
  size,
}: {
  profileImage: string | null;
  size?: string;
}) => {
  return (
    <img
      src={
        profileImage
          ? profileImage.match(/png|jpeg|jpg/g)
            ? `${process.env.REACT_APP_API_URL}${profileImage}`
            : `${profileImage}`
          : "/images/dummy-profile.png"
      }
      className={`rounded-full ${size ? size : "w-10 h-10"} object-cover`}
      alt=""
      crossOrigin="anonymous"
    />
  );
};

export default ProfileImage;
