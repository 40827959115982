import { createSlice } from "@reduxjs/toolkit";

export interface ISidebarResponsiveType {
  sidebarResponsive: boolean;
}

const initialState = {
  sidebarResponsive: false,
};

export const sidebarResponsiveSlice = createSlice({
  name: "sidebarResponsive",
  initialState,
  reducers: {
    showSidebarResponsive: (state: ISidebarResponsiveType) => {
      state.sidebarResponsive = true;
    },
    hideSidebarResponsive: (state: ISidebarResponsiveType) => {
      state.sidebarResponsive = false;
    },
  },
});

export const sidebarResponsiveSelector = (state: {
  sidebarResponsive: ISidebarResponsiveType;
}) => {
  return state.sidebarResponsive.sidebarResponsive;
};

const { actions, reducer } = sidebarResponsiveSlice;

export const { showSidebarResponsive, hideSidebarResponsive } = actions;

export default reducer;
