import * as Yup from "yup";

const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const ProjectValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required("Project Name is required"),
  categoryId: Yup.object()
    .shape({
      id: Yup.string().required("Project Category is required"),
    })
    .required("Project category is required")
    .nullable(),
  description: Yup.string().trim().required("Project Description is required"),
  video: Yup.lazy((value) => {
    switch (typeof value) {
      case "string":
        return Yup.string();
      default:
        return Yup.mixed()
          .nullable()
          .test("fileType", "Only video files are allowed", (value) => {
            if (!value) return true;
            return value?.type?.startsWith("video/");
          });
    }
  }),
  frontEndTechnologies: Yup.string().trim().nullable(),
  backEndTechnologies: Yup.string().trim().nullable(),
  thirdPartyTechnologies: Yup.string().trim().nullable(),
  gumletVideoURL: Yup.string()
    .trim()
    .nullable()
    .test(
      "is-valid-url",
      "Enter a valid URL for Gumlet Video",
      (value) => !value || URL.test(value)
    )
    .when("$isRequired", (isRequired, schema) =>
      isRequired ? schema.required("Gumlet Video Link is required") : schema
    ),
  eSparkPortfolioLink: Yup.string()
    .trim()
    .nullable()
    .test(
      "is-valid-url",
      "Enter a valid URL for eSpark Portfolio Link",
      (value) => !value || URL.test(value)
    )
    .when("$isRequired", (isRequired, schema) =>
      isRequired ? schema.required("eSpark Portfolio Link is required") : schema
    ),

  credentials: Yup.array().of(
    Yup.object().shape({
      enviornmentType: Yup.object()
        .shape({
          value: Yup.string().required("Enviornment type is required"),
        })
        .required("Enviornment type is required")
        .nullable(),
      userName: Yup.string().trim().required("Username or Email is required"),
      password: Yup.string().trim().required("Password is required"),
      stakeholder: Yup.string().trim().required("Stakeholder is required"),
      url: Yup.string()
        .trim()
        .matches(URL, "Enter a valid url")
        .required("Url is required"),
    })
  ),

  figma: Yup.array().of(
    Yup.object().shape({
      stakeholder: Yup.string().trim().required("Stakeholder is required"),
      link: Yup.string().trim(),
      figmaFile: Yup.lazy((value) => {
        switch (typeof value) {
          case "string":
            return Yup.string();
          default:
            return Yup.mixed()
              .required("Please select a PDF file")
              .test("fileType", "Only PDF files are allowed", (value) => {
                if (!value) return true;
                return value && value.type === "application/pdf";
              });
        }
      }),
    })
  ),
  videoSection: Yup.array().of(
    Yup.object().shape({
      stakeholder: Yup.string().trim(),
      videoName: Yup.string().trim().required("Video name is required"),
      video: Yup.lazy((value) => {
        switch (typeof value) {
          case "string":
            return Yup.string();
          default:
            return Yup.mixed()
              .required("Please select a video file")
              .test("fileType", "Only video files are allowed", (value) => {
                if (!value) return true;
                return value?.type?.startsWith("video/");
              });
        }
      }),
    })
  ),
});
