import { Control, FieldErrors, useFieldArray } from "react-hook-form";

import Button from "../../components/button/Button";
import InputField from "../../components/Input/InputField";
import { DeleteIcon, PlusIcon } from "../../components/svgIcons/SvgIcons";
import VideoUpload from "../../components/videoUpload/VideoUpload";
import { IProjectForm } from "../../interface/project/ProjectInterface";

export type Props = {
  control?: Control<IProjectForm>;
  errors?: FieldErrors<IProjectForm>;
  setValue?: any;
};

const VideoSectionFileds = ({ control, errors, setValue }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "videoSection",
  });

  return (
    <>
      {fields.map((item, index) => {
        return index === 0 ? (
          <div
            key={item.id}
            className="main_section bg-224BB5/[0.07]  p-5 rounded-xl mb-3"
          >
            <div className="mb-4  flex flex-wrap items-center justify-between">
              <h1 className="text-lg font-medium">Video Section</h1>
              {fields.length > 1 && (
                <span>
                  <DeleteIcon onClick={() => remove(index)} />
                </span>
              )}
            </div>
            <div className="mb-4 flex">
              <div className="flex flex-col gap-1 xl:w-1/2 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
                <InputField
                  name={`videoSection.${index}.stakeholder`}
                  control={control}
                  type="text"
                  placeholder="Enter Stakeholder"
                  errors={errors}
                  label="Stakeholder"
                  parentClass={""}
                />
                {errors?.videoSection &&
                  errors?.videoSection[index] &&
                  errors?.videoSection[index]?.stakeholder && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.videoSection[index]?.stakeholder?.message}`}
                    </span>
                  )}
              </div>
              <div className="flex flex-col gap-1 xl:w-1/2 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
                <InputField
                  name={`videoSection.${index}.videoName`}
                  control={control}
                  type="text"
                  placeholder="Enter Video Name"
                  errors={errors}
                  label="Video Name"
                  parentClass={""}
                />
                {errors?.videoSection &&
                  errors?.videoSection[index] &&
                  errors?.videoSection[index]?.videoName && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.videoSection[index]?.videoName?.message}`}
                    </span>
                  )}
              </div>
            </div>
            <div className="xl:w-full lg:w-full w-full lg:px-3 px-0 mb-5">
                <VideoUpload
                  name={`videoSection.${index}.video`}
                  control={control}
                  placeholder="Select Video"
                  errors={errors}
                  label="Project Video"
                  setvalue={setValue}
                  parentClass={""}
                  />
                    {errors?.videoSection &&
                  errors?.videoSection[index] &&
                  errors?.videoSection[index]?.video && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.videoSection[index]?.video?.message}`}
                    </span>
                  )}
                  </div>
          </div>
        ) : (
          <div
            key={item.id}
            className="main_section bg-224BB5/[0.07]  p-5 rounded-xl mb-3"
          >
            <div className="mb-4  flex flex-wrap items-center justify-between">
              <h1 className="text-lg font-medium">Video Section</h1>
              {fields.length > 1 && (
                <span>
                  <DeleteIcon onClick={() => remove(index)} />
                </span>
              )}
            </div>
            <div className="mb-4 flex">
              <div className="flex flex-col gap-1 xl:w-1/2 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
                <InputField
                  name={`videoSection.${index}.stakeholder`}
                  control={control}
                  type="text"
                  placeholder="Enter Stakeholder"
                  errors={errors}
                  label="Stakeholder"
                  parentClass={""}
                />
                {errors?.videoSection &&
                  errors?.videoSection[index] &&
                  errors?.videoSection[index]?.stakeholder && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.videoSection[index]?.stakeholder?.message}`}
                    </span>
                  )}
              </div>
           
              <div className="flex flex-col gap-1 xl:w-1/2 lg:w-1/2 w-full lg:px-3 px-0 mb-5">
                <InputField
                  name={`videoSection.${index}.videoName`}
                  control={control}
                  type="text"
                  placeholder="Enter Video Name"
                  errors={errors}
                  label="Video Name"
                  parentClass={""}
                />
                {errors?.videoSection &&
                  errors?.videoSection[index] &&
                  errors?.videoSection[index]?.videoName && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.videoSection[index]?.videoName?.message}`}
                    </span>
                  )}
              </div>

            </div>
            <div className="xl:w-full lg:w-full w-full lg:px-3 px-0 mb-5">
                <VideoUpload
                  name={`videoSection.${index}.video`}
                  control={control}
                  placeholder="Select Video"
                  errors={errors}
                  label="Project Video"
                  setvalue={setValue}
                  parentClass={""}
                  />
                   {errors?.videoSection &&
                  errors?.videoSection[index] &&
                  errors?.videoSection[index]?.video && (
                    <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
                      {`${errors?.videoSection[index]?.video?.message}`}
                    </span>
                  )}
                  </div>
          </div>
        );
      })}
      <div className="inline-block">
        <Button
          buttonVariant={"primary"}
          text="Add More Video"
          type="button"
          icon={<PlusIcon />}
          onClickHandler={() => {
            append({
              stakeholder: "",
              videoName: "",
              video: null,
            });
          }}
          className="text-white capitalize"
          parentClassName="!ml-0 mb-2"
        />
      </div>
    </>
  );
};

export default VideoSectionFileds;
