import { FC, ReactNode } from "react";
import { Controller, FieldName, FieldValues } from "react-hook-form";
import Select, { StylesConfig } from "react-select";

import { FormatString } from "../../helpers/Util";

export type SelectProps<TFieldValues extends FieldValues = FieldValues> = {
  control: any;
  errors?: any;
  name: FieldName<TFieldValues>;
  options: any;
  defaultValue?: string;
  label: string;
  labelKey?: string;
  valueKey?: string;
  formatLabel?: boolean;
  isMulti?: boolean;
  noDataMessage?: string | ReactNode | undefined;
  disabled?: boolean;
  parentClass?: string;
};

export const CustomStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    boxShadow: "unset",
    borderColor: "#dddddd",
    "&:hover": {
      boxShadow: "unset",
    },
    "&:focus": {
      boxShadow: "unset",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 400,
  }),
};
export const CustomSelect: FC<SelectProps> = ({
  name,
  label,
  defaultValue,
  options,
  control,
  noDataMessage,
  errors = {},
  labelKey = "label",
  valueKey = "value",
  disabled,
  formatLabel = false,
  isMulti = false,
  parentClass = {},
}) => {
  return (
    <div className={`input-item relative ${parentClass && parentClass}`}>
      <label className="block mb-2 text-15px">{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
       
            <Select
              className="custom_select"
              defaultValue={defaultValue}
              value={value}
              isMulti={isMulti}
              isDisabled={disabled}
              placeholder={`${"Select"}....`}
              noOptionsMessage={() =>
                noDataMessage ? noDataMessage : "No Data Found"
              }
              styles={CustomStyles}
              getOptionLabel={(option) =>
                formatLabel
                  ? option[labelKey] && FormatString(option[labelKey])
                  : option[labelKey]
              }
              getOptionValue={(option) => option[valueKey]}
              options={options}
              onChange={(e) => {
                onChange(e);
              }}
            />
         
        )}
      />
        {errors?.[name]&&<span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
          {!isMulti
            ? errors?.[name]?.message
            : errors?.[name]?.[valueKey]?.message}
        </span>}
      
    </div>
  );
};

export default CustomSelect;
