import { createSlice } from '@reduxjs/toolkit'

export interface ITabType {
 active:string|null

}

const initialState = {
    active:'info'
}

export const toggleTab = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setActiveTab: (state: ITabType, action: {payload:string|null}) => {
      state.active = action.payload
    },
  },
})

export const tabSelector = (state: { tab: ITabType }) => {
  return state.tab.active
}

const { actions, reducer } = toggleTab

export const { setActiveTab } = actions

export default reducer
