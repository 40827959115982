import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import { Store } from 'redux'

import { SetSession } from '../contexts/AuthContext'
import { ToastShow } from '../redux/ducks/toast.duck'
import { removeToken, setUser } from '../redux/ducks/user.duck'

const onlyLoggedInUserRequestURL = [
  '/manageAccount/childAccounts',
  '/manageAccount/parentAccounts',
  '/manageAccount/request',
]

const SetupAxios = (store: Store) => {
  axios.interceptors.request.use((request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    
    const storeData = store.getState()
    const authToken =
      onlyLoggedInUserRequestURL.findIndex((value) => (request?.url as string).includes(value)) > -1
        ? storeData.user.loggedUserToken
        : storeData.user.token

    if (request.headers !== undefined && authToken) {
      request.headers.Authorization = `jwt ${authToken}`
      request.headers['Content-Type'] = 'multipart/form-data'
    } else {
      SetSession(null)
    }
    return request
  })
  axios.interceptors.response.use(
    (res) => {
      const toast = res?.data?.toast
      if (toast) {
        store.dispatch(ToastShow({ message: res.data.message, type: res.data.response_type }))
      }
      return res
    },
    (e) => {
      const toast = e.response?.data?.toast
      if (toast) {
        store.dispatch(ToastShow({ message: e.response.data.message, type: e.response.data.response_type }))
      }

      const storeData = store.getState()
      if (storeData.user.token !== null) {
        if (e?.response?.status === 401 || e?.response?.status === 403) {
          if (e.response.message) {
            store.dispatch(ToastShow({ message: e.response.message, type: e.response.data.response_type }))
          }
          store.dispatch(removeToken())
          store.dispatch(setUser(null))
        }
      }

      return e.response
    },
  )
}

export default SetupAxios

export function AxiosGet<T>(url: string, data: T | null = null) {
  return axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
    params: data,
  })
}

export function AxiosPost<T>(url: string, data: T | object, headers?: AxiosRequestConfig) {
  return axios.post(`${process.env.REACT_APP_API_URL}${url}`, data, headers ? headers : {})
}

export const AxiosConfig = (method: string, url: string, config: AxiosRequestConfig, data: object) => {
  return axios({ method: method, url: `${process.env.REACT_APP_API_URL}${url}`, ...config, data })
}

export const AxiosPatch = (url: string, data: object) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}${url}`, data)
}

export const AxiosPut = (url: string, data: object) => {
  return axios.put(`${process.env.REACT_APP_API_URL}${url}`, data)
}

export const AxiosDelete = (url: string) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}${url}`)
}
