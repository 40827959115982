import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import BackButton from "../../components/button/BackButton";
import DashboardLoader from "../../components/loader/DashboardLoader";
import { PDFIcon } from "../../components/svgIcons/SvgIcons";
import { siteLoaderSelector } from "../../redux/ducks/siteLoader.duck";
import Card from "../../wrapper/Card";

const ViewProjectDetail = () => {
  const location = useLocation();
  const siteLoader = useSelector(siteLoaderSelector);

  useEffect(() => {}, [location.state]);
  return (
    <div>
      <BackButton title="Projects" />
      {siteLoader ? (
        <DashboardLoader />
      ) : (
        <Card title={location?.state?.name} link="user" isButton={false}>
          <>
            {(location.state?.gumletVideoURL || location.state?.video) && (
              <div className="bg-white rounded-10px mb-7 p-5 shadow-shadow2">
                {location?.state?.video && (
                  <div className="flex-wrap items-center mb-2">
                    <h4 className="text-lg font-medium mb-3">Gumlet Video :</h4>
                    <video controls className="w-auto h-[250px]">
                      <source
                        src={` ${process.env.REACT_APP_API_URL}/${location?.state?.video}`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
                {location?.state?.gumletVideoURL && (
                  <div className="flex items-center mt-8">
                    <h4 className="text-lg font-medium mb-0">
                      Gumlet Video URL:
                    </h4>
                    <span className="inline-block ml-2 font-medium">
                      {location.state?.gumletVideoURL}
                    </span>
                  </div>
                )}
              </div>
            )}

            <div className="bg-white rounded-10px mb-7 p-5 shadow-shadow2">
              <div className="xl:flex block flex-wrap justify-between items-center">
                <div className="sm:flex block flex-wrap items-center">
                  <div className="sm:mt-0 mt-5">
                    <div className="flex flex-wrap items-center mb-2">
                      <h4 className="text-lg font-medium">
                        Category Name :{" "}
                        {location?.state?.category?.name
                          ? location?.state?.category?.name
                          : "-"}
                      </h4>
                    </div>

                    <span className="text-slate-500">
                      {location?.state?.description
                        ? location?.state?.description
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="font-medium mb-5 text-lg relative pl-7 before:w-5 before:h-[6px] before:bg-3369FD before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:rounded-10px">
              Technologies Section
            </h3>
            <div className="bg-white rounded-10px mb-7 p-5 shadow-shadow2">
              <h5 className="text-slate-500">
                FrontEnd Technologies :{" "}
                {location?.state?.frontEndTechnologies
                  ? location?.state?.frontEndTechnologies
                  : "-"}
              </h5>
              <h5 className="text-slate-500">
                Backend Technologies :{" "}
                {location?.state?.backEndTechnologies
                  ? location?.state?.backEndTechnologies
                  : "-"}
              </h5>
              <h5 className="text-slate-500">
                Third Party Technologies :{" "}
                {location?.state?.thirdPartyTechnologies
                  ? location?.state?.thirdPartyTechnologies
                  : "-"}
              </h5>
            </div>

            {location?.state?.figma?.length > 0 && (
              <div className="mt-5">
                <div className="mb-5">
                  <div className="mb-5">
                    <h3 className="font-medium mb-5 text-lg relative pl-7 before:w-5 before:h-[6px] before:bg-3369FD before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:rounded-10px">
                      Figma Section
                    </h3>
                  </div>
                  <div className="flex flex-wrap -mx-1">
                    {location?.state?.figma?.map((item: any) => (
                      <div
                        className="md:mx-3 mx-0 bg-white shadow-shadow2 mb-6 p-4 rounded-10px"
                        key={item.id}
                      >
                        <div className=" mx-1 bg-slate-200 mb-6 p-1 rounded-10px overflow-hidden group">
                          <h4 className="text-lg font-medium">
                            Stakeholder :
                            {item?.stakeholder ? item?.stakeholder : "-"}
                          </h4>
                        </div>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${item?.link}`}
                        >
                          <div className=" mx-1 bg-slate-200 mb-6 p-1 rounded-10px overflow-hidden group ">
                            {" "}
                            {item?.link ?? "-"}
                          </div>
                        </a>
                        <div className="mx-1 bg-slate-200 mb-6 p-1 rounded-10px overflow-hidden group">
                          <div className="w-[250px] pt-[100%] relative overflow-hidden rounded-5px">
                            <Link
                              target="_blank"
                              to={`${process.env.REACT_APP_API_URL}/${item?.figmaFile}`}
                              className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-7xl text-3369FD"
                            >
                              <PDFIcon className="duration-300 transition-all group-hover:scale-[1.05]" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {location?.state?.credentials?.length > 0 && (
              <div className="mt-5">
                <div className="mb-5">
                  <div className="mb-5">
                    <h3 className="font-medium mb-5 text-lg relative pl-7 before:w-5 before:h-[6px] before:bg-3369FD before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:rounded-10px">
                      Credentials
                    </h3>
                  </div>
                  <div className="flex flex-wrap md:-mx-3 mx-0">
                    {location?.state?.credentials?.map((item: any) => (
                      <div
                        className="xl:w-[calc(33.33%_-_24px)] md:w-[calc(50%_-_24px)] w-full md:mx-3 mx-0 bg-white shadow-shadow2 mb-6 p-4 rounded-10px"
                        key={item.id}
                      >
                        <div className="mb-3">
                          <div className="flex flex-wrap">
                            <label className="w-[130px]">
                              Enviornment Type
                            </label>
                            <div className="w-[calc(100%_-_130px)] pl-3 text-slate-500">
                              <span>{item?.enviornmentType ?? "-"}</span>
                            </div>
                            <label className="w-[130px]">Stakeholder</label>
                            <div className="w-[calc(100%_-_130px)] pl-3 text-slate-500">
                              <span>{item?.stakeholder ?? "-"}</span>
                            </div>
                            <label className="w-[130px]">URL</label>
                            <div className="w-[calc(100%_-_130px)] pl-3 text-slate-500">
                              <span>
                                <a
                                  rel="noreferrer"
                                  target="_blank"
                                  href={`${item?.link}`}
                                >
                                  {" "}
                                  {item?.url ?? "-"}
                                </a>
                              </span>
                            </div>
                            <label className="w-[130px]">
                              User Name or Email
                            </label>
                            <div className="w-[calc(100%_-_130px)] pl-3 text-slate-500">
                              <span>{item?.userName ?? "-"}</span>
                            </div>
                            <label className="w-[130px]">Password</label>
                            <div className="w-[calc(100%_-_130px)] pl-3 text-slate-500">
                              <span>{item?.password ?? "-"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {location?.state?.videoSection?.length > 0 && (
              <div className="mt-5">
                <div className="mb-5">
                  <div className="mb-5">
                    <h3 className="font-medium mb-5 text-lg relative pl-7 before:w-5 before:h-[6px] before:bg-3369FD before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:rounded-10px">
                      Video Section
                    </h3>
                  </div>
                  <div className="flex flex-wrap -mx-1">
                    {location?.state?.videoSection?.map((item: any) => (
                      <div
                        className="md:mx-3 mx-0 bg-white shadow-shadow2 mb-6 p-4 rounded-10px"
                        key={item.id}
                      >
                        <div className=" mx-1 bg-slate-200 mb-6 p-1 rounded-10px overflow-hidden group">
                          <h4 className="text-lg font-medium">
                            Stakeholder :
                            {item?.stakeholder ? item?.stakeholder : "-"}
                          </h4>
                        </div>

                        <div className=" mx-1 bg-slate-200 mb-6 p-1 rounded-10px overflow-hidden group ">
                          Video Name : {item?.videoName ?? "-"}
                        </div>

                        <video controls className="w-auto h-[250px]">
                          <source
                            src={` ${process.env.REACT_APP_API_URL}/${item?.stakeholderVideo}`}
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        </Card>
      )}
    </div>
  );
};

export default ViewProjectDetail;
