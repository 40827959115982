import { Controller, FieldErrors } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import ImageUploading from "react-images-uploading";

interface ImageProps {
  multiple: boolean;
  label?: string;
  parentClass?: string;
  control: any;
  errors: FieldErrors | any;
  name: string;
}

export const ImageUploadForm =({
  multiple,
  label,
  parentClass,
  control,
  errors,
  name,
}: ImageProps) =>{
  const maxNumber = 69;

  return (
    <div className={`input-item relative ${parentClass && parentClass}`}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ImageUploading
            {...field}
            multiple={multiple}
            maxNumber={maxNumber}
            acceptType={["jpg", "png", "jpeg"]}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <>
                <label className="block mb-2 text-15px">
                  {label ? label : ""}
                </label>
                <div
                  className={`text-sm p-2.5 cursor-pointer rounded-lg border border-dashed border-gray-500 flex flex-wrap items-center min-h-[48px] ${
                    multiple ? "" : "justify-center"
                  }`}
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <span
                    className={`${
                      imageList.length > 0
                        ? "hidden"
                        : "block text-center w-full"
                    }`}
                  >
                    {"Select image OR drag and drop file here"}
                  </span>
                  {imageList?.length > 0 &&
                    imageList.map((image, index) => (
                      <div
                        key={index}
                        className={`image-item ${multiple ? "m-1" : ""}`}
                      >
                        <div className="group relative w-24 h-24 bg-dddddd flex items-center justify-center rounded-xl">
                          <span className="absolute flex items-center justify-center bg-black/50 text-white text-2xl opacity-0 group-hover:opacity-100 w-full h-full left-0 top-0 rounded-xl transition-all duration-300">
                            <MdCancel
                              onClick={(e) => {
                                onImageRemove(index);
                                imageList.length ===1&&field.onChange(null) // just for one 
                                e.stopPropagation();
                              }}
                            />
                          </span>

                          <img
                            src={image.dataURL}
                            alt=""
                            className="w-full h-full object-contain object-center"
                            width={"100%"}
                            height={"100%"}
                            crossOrigin="anonymous"
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </ImageUploading>
        )}
      />
      {errors[name] && (
        <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
          {errors[name]?.message}
        </span>
      )}
    </div>
  );
}

