import React, { useRef } from "react";
import { Controller, FieldErrors } from "react-hook-form";

import { DeleteIcon } from "../svgIcons/SvgIcons";

interface VideoUploadProps {
  name: string;
  label: string;
  placeholder?: string;
  errors: FieldErrors | any;
  className?: string;
  parentClass?: string;
  control: any;
  setvalue: any;
}

const VideoUpload: React.FC<VideoUploadProps> = ({
  name,
  label,
  placeholder,
  className,
  errors,
  parentClass = {},
  control,
  setvalue,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className={`input-item relative ${parentClass && parentClass}`}>
      {label && (
        <label htmlFor={name} className="block mb-2 text-15px">
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => {
          return (
            <div className="flex items-start py-3">
              {!field.value && (
                <input
                  {...field}
                  ref={inputRef}
                  value={field.value?.fileName}
                  type="file"
                  accept="video/*"
                  onChange={(e) => {
                    field.onChange(e.target.files?.[0]);
                  }}
                  placeholder={placeholder ?? ""}
                  className={`${
                    errors[name] ? "border-red-500 " : "border-inputborder "
                  } ${
                    className ?? ""
                  }w-full placeholder:text-zinc-500 border border-slate-200 py-2 min-h-[48px] px-4 focus:outline-none rounded-lg`}
                />
              )}

              {field.value && field.value !== "" && field.value !== null && (
                <video controls className="w-auto h-[250px]">
                  <source
                    src={
                      typeof field.value === "object"
                        ? URL.createObjectURL(field.value)
                        : field.value
                    }
                    type={field.value.type}
                  />
                  Your browser does not support the video tag.
                </video>
              )}
              {field.value && (
                <DeleteIcon
                  className="w-10 h-10"
                  onClick={() => {
                    setvalue(name, null);
                    field.onChange(null);
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                />
              )}
            </div>
          );
        }}
      />

      {errors[name] && (
        <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
          {errors[name]?.message}
        </span>
      )}
    </div>
  );
};

export default VideoUpload;
