import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import setupAxios from "./axios/Config";
import ToastNotification from "./components/toastNotification/ToastNotification";
import Wrapper from "./components/wrapper/Wrapper";
import LayOut from "./defaultLayout/Index";
import {
  removeToken,
  setUser,
  tokenSelector,
  userSelector,
} from "./redux/ducks/user.duck";
import { store } from "./redux/store";
import { AuthRoutes, RoutesPath } from "./route/Routes";

import "./App.css";
setupAxios(store);

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const token = useSelector(tokenSelector);

  const getLoggedUser = async () => {
    if (token === null && (user === null || Array.isArray(user))) {
      dispatch(setUser(null));
      dispatch(removeToken());
      AuthRoutes.findIndex((e) => e.path.includes(location.pathname)) === -1 &&
        navigate("/login");
    }
  };

  useEffect(() => {
    getLoggedUser();
  }, [user, token]);

  return (
    <Provider store={store}>
      <Routes>
        {AuthRoutes.map((route) => (
          <Route
            key={route?.id}
            path={route.path}
            element={
              <Wrapper>
                <route.element />
              </Wrapper>
            }
          />
        ))}
        <Route path="/" element={<LayOut />}>
          {RoutesPath.map((route) => (
            <Route key={route?.id} path={route.path} element={<route.element />} />
          ))}
        </Route>
      </Routes>
      <ToastNotification />
    </Provider>
  );
}

export default App;
