import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ImageListType, ImageType } from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../../components/button/Button";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import InputField from "../../components/Input/InputField";
import Modal from "../../components/modal/Modal";
import { setToggleModal } from "../../redux/ducks/modal.duck";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../redux/ducks/siteLoader.duck";
import {
  setUser,
  userSelector,
} from "../../redux/ducks/user.duck";
import {  UpdateAdmin } from "../../services/AuthServices";

const EditProfileModal = () => {
  const dispatch = useDispatch();
  const siteLoader = useSelector(siteLoaderSelector);
  const currentUser = useSelector(userSelector);
  const [images, setImages] = useState<ImageListType>([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ email: string; firstName: string;lastName: string }>({
    resolver: yupResolver(
      Yup.object().shape({
        firstName: Yup.string().trim().required("First name is required"),
        lastName: Yup.string().trim().required("Last name is required"),
      })
    ),
    mode: "onChange",
    defaultValues: {
      firstName: currentUser?.firstName ?? "",
      lastName: currentUser?.lastName ?? "",
      email: currentUser?.email,
    },
  });
  const FetchCurrentUserImage = async () => {
    currentUser?.profileImage
      ? setImages([
          {
            dataURL: currentUser?.profileImage
              ? `${process.env.REACT_APP_API_URL}${currentUser?.profileImage}`
              : undefined,
          },
        ])
      : setImages([]);
  };
  useEffect(() => {
    if (currentUser?.profileImage) {
      FetchCurrentUserImage();
    }
  }, [currentUser]);

  useEffect(() => {
    reset({ firstName: currentUser?.firstName, lastName: currentUser?.lastName, email: currentUser?.email });
  }, [currentUser]);

  const OnSubmit = async (data: { email: string; firstName: string;lastName:string }) => {
    dispatch(showLoader());

    const reqObj = new FormData();
    reqObj.append("firstName", data.firstName);
    reqObj.append("lastName", data.lastName);

    let updateImages: [ImageType?, string?, null?] = [];
    images.forEach((file) => {
      if (file.file instanceof File) reqObj.append("profile", file.file);
      else if (file.dataURL && process.env.REACT_APP_PUBLIC_SOCKET_URL)
        updateImages.push(
          file.dataURL.replace(process.env.REACT_APP_PUBLIC_SOCKET_URL, "")
        );
      else {
        reqObj.append(
          "profile",
          updateImages.length >= 1 && updateImages
            ? JSON.stringify(updateImages)
            : JSON.stringify(null)
        );
      }
    });
    reqObj.append(
      "profile",
      updateImages.length >= 1 && updateImages
        ? JSON.stringify(updateImages)
        : JSON.stringify(null)
    );
    const response = await UpdateAdmin(
      currentUser ? currentUser.id : "",
      reqObj
    );

    const { response_type, message } = response.data;
    if (response_type === "success") {
      toast.success(message);
      dispatch(setUser(response.data.responseData));
      dispatch(setToggleModal(null));
    } else {
      toast.error(message);
    }
    reset();
    dispatch(hideLoader());
  };

  return (
    <Modal Title="Update Profile" isButton={false} toggle="changePasswordModal">
      <form className="space-y-6" onSubmit={handleSubmit(OnSubmit)}>
        <div className="mb-6">
          <InputField
            type="text"
            name="firstName"
            control={control}
            placeholder="First name"
            errors={errors}
            label="First name"
          />
           <InputField
            type="text"
            name="lastName"
            control={control}
            placeholder="Last ame"
            errors={errors}
            label="Last name"
          />
        </div>

        <div className="mb-6">
          <InputField
            type="email"
            name="email"
            disable={true}
            control={control}
            placeholder="Email"
            errors={errors}
            label="Email"
          />
        </div>
        <div className="w-full">
          <ImageUpload
            images={images}
            setImages={setImages}
            label={"Update Profile Image"}
            multiple={false}
          />
        </div>
        <Button
          buttonVariant={"primary"}
          text={"Update Profile"}
          type={"submit"}
          className="text-white w-full text-base min-h-[48px] flex items-center justify-center"
          parentClassName="!ml-0 "
          loader={siteLoader}
        />
      </form>
    </Modal>
  );
};

export default EditProfileModal;
