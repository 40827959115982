import { Key, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { ISidebar } from "../../interface/sidebar/SidebarInterface.";
import { hideSidebarResponsive } from "../../redux/ducks/sidebarResponsive.duck";
import { sidebarRoutes } from "../../route/SidebarRoutes";

const SubMenu = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [sideBar, setSideBar] = useState<ISidebar[]>(sidebarRoutes);
  const [sidebarToggle] = useState<boolean>(false);

  const ShowSubNav = (title: string) => {
    const temp = sideBar?.map((items: ISidebar) => {
      return {
        ...items,
        open: title === items.title && !items.open,
      };
    });
    setSideBar(temp);
  };

  return (
    <>
      {sideBar?.map((item: ISidebar, index: Key | null | undefined) => (
        <div key={index}>
          <NavLink
            to={item?.path}
            onClick={() => {
              ShowSubNav(item.title);
              dispatch(hideSidebarResponsive());
            }}
            className={`${
              item.subNav ? null : "nav-link"
            } select-none flex flex-wrap rounded-md cursor-pointer hover:text-[#3369FD] transition duration-500 transform  text-[#9e9ebc] font-medium items-center tracking-wider py-2 ${
              sidebarToggle ? "justify-center" : ""
            }`}
          >
            {<item.icon />}
            {sidebarToggle ? (
              ""
            ) : (
              <>
                <span className="flex-1 ml-4">{item.title}</span>
                {item.subNav && (
                  <BsChevronDown
                    className={`${item.open && "rotate-180"}`}
                    size={15}
                  />
                )}
              </>
            )}
          </NavLink>
          <ul className="mt-4 ml-3 pl-18px border-l border-solid border-primaryText/20">
            {!sidebarToggle &&
              item.subNav &&
              item.open &&
              item.subNav.map(
                (
                  item: { path: string; title: string },
                  index: Key | null | undefined
                ) => {
                  return (
                    <li key={index}>
                      <NavLink
                        to={item.path}
                        onClick={() => {
                          dispatch(hideSidebarResponsive());
                        }}
                        className={`${
                          pathname === item.path ||
                          pathname === `${item.path}/add` ||
                          pathname ===
                            `${item.path}/edit/${pathname?.split("/").pop()}`
                            ? "nav-link"
                            : ""
                        } flex px-5 cursor-pointer font-medium text-[#9e9ebc] py-3 hover:text-[#3369FD] transition duration-300 transform`}
                      >
                        <label className="cursor-pointer">{item.title}</label>
                      </NavLink>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      ))}
    </>
  );
};

export default SubMenu;
