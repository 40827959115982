import { RiLockPasswordLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { setToggleModal } from "../../redux/ducks/modal.duck";
import { showSidebarResponsive } from "../../redux/ducks/sidebarResponsive.duck";
import { userSelector } from "../../redux/ducks/user.duck";
import ProfileImage from "../imageUpload/ProfileImage";
import { ResponsiveIcon, UserSettingIcon } from "../svgIcons/SvgIcons";

const Header = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(userSelector);

  return (
    <div className="main-header lg:shadow-none shadow-[0_0_10px_0_rgba(0,0,0,0.1)] lg:mb-5 mb-0 lg:bg-transparent bg-white lg:py-0 lg:px-0 px-4 py-3 lg:relative fixed top-0 left-0 right-0 z-10">
      <div className="flex flex-wrap items-center lg:justify-end justify-between relative">
        <div className="w-[160px] absolute left-1/2 -translate-x-1/2 block lg:hidden">
          <img
            src={`/images/Logo.png`}
            className="w-40 h-10 object-contain"
            alt=""
          />
        </div>
        <div className="inline-block lg:hidden relative">
          <Link
            to="#"
            className="absolute left-0 top-1/2 -translate-y-1/2"
            onClick={() => dispatch(showSidebarResponsive())}
          >
            <ResponsiveIcon />
          </Link>
        </div>
        <div className="flex flex-wrap items-center justify-end">
          <div className="relative ml-6 group">
            <div className="flex items-center flex-wrap bg-white lg:py-2 py-0 lg:px-3 px-0 rounded-lg shadow-shadow1 cursor-pointer ">
              <ProfileImage
                profileImage={currentUser && currentUser.profileImage}
                size="w-8 h-8 object-cover rounded-lg"
              />
              <span className="text-15px  lg:inline-block hidden ml-2 ">
              {currentUser?.firstName} {currentUser?.lastName}
              </span>
            </div>
            <div className="bg-white shadow-xl rounded-10px w-[320px] absolute right-0 top-14 z-10 invisible opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100 ">
              <div className="pt-4 pl-4">
                <span className="font-bold">Account</span>
              </div>
              <div className="border-b border-slate-200 px-4 pb-5 pt-3">
                <div className="flex items-center flex-wrap">
                  <div className="w-[50px]">
                    <ProfileImage
                      size="w-10 h-10 object-cover rounded-lg"
                      profileImage={currentUser && currentUser.profileImage}
                    />
                  </div>
                  <div className="w-[calc(100%_-_50px)] pl-3">
                    <h2 className="font-bold capitalize">
                      {currentUser?.firstName} {currentUser?.lastName}
                    </h2>
                    <span className="text-sm text-slate-600">
                      {currentUser?.email}
                    </span>
                  </div>
                </div>
              </div>
              <ul className="text-15px font-medium">
                <li
                  onClick={() => dispatch(setToggleModal("updateProfileModal"))}
                  className="border-b border-slate-200 px-4 py-3"
                >
                  <Link
                    to="#"
                    className="flex flex-wrap items-center hover:text-3369FD ease-linear duration-200"
                  >
                    <UserSettingIcon />
                    <span className="inline-block ml-3">Update Profile</span>
                  </Link>
                </li>
                <li
                  className="px-4 py-3"
                  onClick={() =>
                    dispatch(setToggleModal("changePasswordModal"))
                  }
                >
                  <Link
                    to="#"
                    className="flex flex-wrap items-center hover:text-3369FD ease-linear duration-200"
                  >
                    <RiLockPasswordLine size={22} />
                    <span className="inline-block ml-3">Change Password</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
