import {
  CategoryIcon,
  DashboardIcon,
  ProjectIcon,
} from "../components/svgIcons/SvgIcons";
import { ISidebar } from "../interface/sidebar/SidebarInterface.";

export const sidebarRoutes: ISidebar[] = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: DashboardIcon,
  },
  {
    title: "Categories",
    path: "/categories",
    icon: CategoryIcon,
  },
  {
    title: "Projects",
    path: "/projects",
    icon: ProjectIcon,
  },
];
