import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../../components/button/Button";
import InputField from "../../components/Input/InputField";
import { BackArrowIcon } from "../../components/svgIcons/SvgIcons";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../redux/ducks/siteLoader.duck";
import { ForgotPasswords } from "../../services/AuthServices";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siteLoader = useSelector(siteLoaderSelector);
  const EmailValidationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is Invalid"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    resolver: yupResolver(EmailValidationSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const OnSubmit = async (data: { email: string }) => {
    dispatch(showLoader());
    const response = await ForgotPasswords({ email: data.email,type:"FORGOT" });
    const { response_type, message } = response.data;

    if (response_type === "success") {
      navigate("/otp-verification", { state: { data } });
    } else {
      toast.error(message);
    }
    dispatch(hideLoader());
  };

  return (
    <>
      <div className="text-center mb-8">
        <h1 className="xl:text-3xl lg:text-2xl text-xl font-semibold mb-6">
          Forgot Password ?
        </h1>
        <p className="text-gray-700 xl:text-lg lg:text-base text-sm">
          Please enter your email address
        </p>
      </div>
      <form className="auth_form" onSubmit={handleSubmit(OnSubmit)}>
        <InputField
          name="email"
          control={control}
          type="text"
          placeholder="Enter Email"
          errors={errors}
          parentClass={"mb-4"}
        />

        <Button
          buttonVariant={"primary"}
          text={"Send Reset Link"}
          type={"submit"}
          loader={siteLoader}
          parentClassName="!m-0"
          className="text-white min-h-[48px] text-base flex items-center justify-center"
        />
      </form>
      <span
        onClick={() => navigate(-1)}
        className="flex items-center justify-end my-5 cursor-pointer"
      >
        <BackArrowIcon /> back to login
      </span>
    </>
  );
};

export default ForgotPassword;
