import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../../components/button/Button";
import InputField from "../../components/Input/InputField";
import Modal from "../../components/modal/Modal";
import { HidePassword, ShowPassword } from "../../components/svgIcons/SvgIcons";
import { IUserSubmitForm } from "../../interface/login/ChangePasswordInterface";
import { setToggleModal } from "../../redux/ducks/modal.duck";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../redux/ducks/siteLoader.duck";
import { UpdatePassword } from "../../services/AuthServices";
import {
  ConfirmPasswordValidation,
  CurrentPasswordValidation,
  NewPasswordValidation,
} from "../../validation/login/PasswordValidation";

const ChangePasswordModal = () => {
  const dispatch = useDispatch();

  const siteLoader = useSelector(siteLoaderSelector);

  const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const passwordValidationSchema = Yup.object().shape({
    oldPassword: CurrentPasswordValidation,
    newPassword: NewPasswordValidation,
    confirmPassword: ConfirmPasswordValidation,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IUserSubmitForm>({
    resolver: yupResolver(passwordValidationSchema),
    mode: "onChange",
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const OnSubmit = async (data: IUserSubmitForm) => {
    dispatch(showLoader());

    let params = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    const response = await UpdatePassword(params);
    const { response_type, message } = response.data;
    if (response_type === "success") {
      toast.success(message);
      dispatch(setToggleModal(null));
    } else {
      toast.error(message);
    }
    dispatch(hideLoader());
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if ((e.target as HTMLElement).classList.contains("passwordModal")) {
        reset();
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [isDirty, errors]);

  return (
    <Modal
      Title="Change Password"
      isButton={false}
      toggle="changePasswordModal"
    >
      <form className="space-y-6" onSubmit={handleSubmit(OnSubmit)}>
        <div className="mb-6">
          <InputField
            name="oldPassword"
            control={control}
            placeholder="Current Password"
            errors={errors}
            label="Current Password"
            type={showPassword.oldPassword ? "text" : "password"}
            icon={
              <div
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    oldPassword: !showPassword.oldPassword,
                  })
                }
                className="absolute right-4 top-[46px] cursor-pointer"
              >
                {showPassword.oldPassword ? <ShowPassword /> : <HidePassword />}
              </div>
            }
          />
        </div>
        <div className="mb-6">
          <InputField
            name="newPassword"
            control={control}
            type={showPassword.newPassword ? "text" : "password"}
            placeholder="New Password"
            errors={errors}
            label="New Password"
            icon={
              <div
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    newPassword: !showPassword.newPassword,
                  })
                }
                className="absolute right-4 top-[46px] cursor-pointer"
              >
                {showPassword.newPassword ? <ShowPassword /> : <HidePassword />}
              </div>
            }
          />
        </div>
        <div className="mb-6">
          <InputField
            name="confirmPassword"
            control={control}
            placeholder="Confirm Password"
            type={showPassword.confirmPassword ? "text" : "password"}
            errors={errors}
            label="Confirm Password"
            icon={
              <div
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    confirmPassword: !showPassword.confirmPassword,
                  })
                }
                className="absolute right-4 top-[46px] cursor-pointer"
              >
                {showPassword.confirmPassword ? (
                  <ShowPassword />
                ) : (
                  <HidePassword />
                )}
              </div>
            }
          />
        </div>

        <Button
          buttonVariant={"primary"}
          text={"Save"}
          type={"submit"}
          className="text-white min-h-[48px] text-base flex items-center justify-center"
          parentClassName="!ml-0"
          loader={siteLoader}
        />
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
