import React, { useRef } from "react";
import { Controller, FieldErrors } from "react-hook-form";

import { DeleteIcon, FileBlankIcon } from "../svgIcons/SvgIcons";

interface DocumnetUploadProps {
  name: string;
  label: string;
  placeholder?: string;
  errors: FieldErrors | any;
  className?: string;
  parentClass?: string;
  control: any;
  setvalue: any;
}

const DocumentUpload: React.FC<DocumnetUploadProps> = ({
  name,
  label,
  placeholder,
  className,
  errors,
  parentClass = {},
  control,
  setvalue,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className={`input-item relative ${parentClass && parentClass}`}>
      {label && (
        <label htmlFor={name} className="block mb-2 text-15px">
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => {
          return (
            <div className="flex items-start">
              {!field.value && (
                <input
                  {...field}
                  ref={inputRef}
                  value={field.value?.fileName}
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => {
                    field.onChange(e.target.files?.[0]);
                  }}
                  placeholder={placeholder ?? ""}
                  className={`${
                    errors[name] ? "border-red-500 " : "border-inputborder "
                  } ${
                    className ?? ""
                  }w-full placeholder:text-zinc-500 border border-slate-200 py-2 min-h-[48px] px-4 focus:outline-none rounded-lg`}
                />
              )}
             {field.value&& <div className="flex bg-blue-400 p-2 rounded-md	">
                {field.value && field.value !== "" && field.value !== null && (
                  <div className="justify-center flex">
                    <FileBlankIcon />
                    <label>{typeof field?.value  === "object" ? field.value.name:field.value}</label>
                  </div>
                )}
                {field.value && (
                  <DeleteIcon
                    className="w-10 h-10"
                    onClick={() => {
                      setvalue(name, null);
                      field.onChange(null);
                      if (inputRef.current) {
                        inputRef.current.value = "";
                      }
                    }}
                  />
                )}
              </div>}
            </div>
          );
        }}
      />

      {errors[name] && (
        <span className="validation_error inline-block text-red-500 text-xs font-normal tracking-wide mt-1">
          {errors[name]?.message}
        </span>
      )}
    </div>
  );
};

export default DocumentUpload;
